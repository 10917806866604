/**
 * @generated SignedSource<<87e6644454ec09cc2e81a73d492a4a3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type userInfo_MeQuery$variables = Record<PropertyKey, never>;
export type userInfo_MeQuery$data = {
  readonly me: {
    readonly user: {
      readonly email: string;
      readonly id: string;
      readonly name: string | null | undefined;
      readonly workspaces: ReadonlyArray<{
        readonly customerId: string | null | undefined;
        readonly id: string;
        readonly name: string;
        readonly slug: string;
      }>;
    };
  };
};
export type userInfo_MeQuery = {
  response: userInfo_MeQuery$data;
  variables: userInfo_MeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Me",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Workspace",
            "kind": "LinkedField",
            "name": "workspaces",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "userInfo_MeQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "userInfo_MeQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ec38e65b16cf8b468d190428d7f7d3a9",
    "id": null,
    "metadata": {},
    "name": "userInfo_MeQuery",
    "operationKind": "query",
    "text": "query userInfo_MeQuery {\n  me {\n    user {\n      id\n      email\n      name\n      workspaces {\n        id\n        name\n        slug\n        customerId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0b9d53be1d24e49b1d523ea16b7ae811";

export default node;
