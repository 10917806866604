import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { TableCell } from "@utopia/ui"

import type { descriptionCell_BankTransferFragment$key } from "./__generated__/descriptionCell_BankTransferFragment.graphql"

const BankTransferFragment = graphql`
  fragment descriptionCell_BankTransferFragment on OfframpTransfer {
    description
  }
`

interface DescriptionCellProps {
  bankTransfer: descriptionCell_BankTransferFragment$key
}

export function DescriptionCell({ bankTransfer }: DescriptionCellProps) {
  const bankTransferData = useFragment(BankTransferFragment, bankTransfer)

  return (
    <TableCell className="align-top">
      <div className="">{bankTransferData.description}</div>
    </TableCell>
  )
}
