import InlineSVG from "react-inlinesvg"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { TableCell } from "@utopia/ui"

import { formatAmount, formatToken } from "#utils/tokens.js"

import type { paymentTotalCell_BankTransferFragment$key } from "./__generated__/paymentTotalCell_BankTransferFragment.graphql"

const BankTransferFragment = graphql`
  fragment paymentTotalCell_BankTransferFragment on OfframpTransfer {
    paymentTotalInCents
    tokenAddress
    networkId
  }
`

interface PaymentTotalCellProps {
  bankTransfer: paymentTotalCell_BankTransferFragment$key
}

export function PaymentTotalCell({ bankTransfer }: PaymentTotalCellProps) {
  const bankTransferData = useFragment(BankTransferFragment, bankTransfer)

  const formattedAmount = formatAmount(bankTransferData.paymentTotalInCents)
  const formattedToken = formatToken({
    tokenAddress: bankTransferData.tokenAddress,
    networkId: bankTransferData.networkId,
  })

  return (
    <TableCell className="align-top">
      {formattedToken && (
        <div className="flex flex-wrap items-center justify-end">
          {formattedAmount}
          <InlineSVG src={formattedToken.logoSrc} className="h-5 w-5 p-1 px-1" />{" "}
          {formattedToken.symbol}
        </div>
      )}
    </TableCell>
  )
}
