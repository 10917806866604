import { z } from "zod"

export const addContactSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, "Full legal name is required."),
  email: z.string().email(),
  bankAccounts: z
    .array(
      z
        .object({
          idempotencyKey: z.string(),
          address: z.object({
            city: z.string(),
            country: z.string(),
            postalCode: z.string(),
            state: z.string(),
            streetLine1: z.string(),
            streetLine2: z.string().optional(),
          }),
          accountNumber: z.string(),
          routingNumber: z.string(),
          bankName: z.string(),
          accountOwnerName: z.string(),
        })
        .nullable(),
    )
    .nullable(),
})

export type AddContactData = z.infer<typeof addContactSchema>
