import { PrivyProvider } from "@privy-io/react-auth"
import * as Sentry from "@sentry/react"
import { RouterProvider } from "@tanstack/react-router"
import { Analytics } from "@vercel/analytics/react"
import { ZeroDevProvider } from "@zerodev/privy"
import { Buffer } from "buffer"
import { ThemeProvider } from "next-themes"
import React, { Suspense } from "react"
import ReactDOM from "react-dom/client"
import { RelayEnvironmentProvider } from "react-relay"
import { mainnet } from "wagmi/chains"

import { Toaster } from "@utopia/ui"

import { RelayEnvironment } from "#RelayEnvironment.js"
import { Config } from "#config"
import { NavbarProvider } from "#providers/navbar.js"
import { createRouter } from "#router.js"
import "#services/sentry.js"
import { useUserInfo } from "#store/user-info.js"
import { Mixpanel } from "#utils/mixpanel.js"

import "./globals.css"

globalThis.Buffer = Buffer

const zeroDevOptions = {
  projectId: Config.VITE_ZERODEV_PROJECT_ID!,
}

function loadHubSpotScript() {
  const script = document.createElement("script")
  script.src = "//js.hs-scripts.com/42161454.js" // Replace with your HubSpot ID.
  script.async = true
  script.defer = true
  script.id = "hs-script-loader"
  document.body.appendChild(script)

  // Add custom CSS for HubSpot chat icon
  const style = document.createElement("style")
  style.innerHTML = `
    @media (max-width: 960px) {
      #hubspot-messages-iframe-container {
        left: 0 !important;
        right: auto !important;
      }
    }
  `
  document.head.appendChild(style)
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

// Load Hubspot Chat Script
loadHubSpotScript()

void useUserInfo
  .getState()
  .fetchUserInfo()
  .then(() => {
    root.render(
      <ZeroDevProvider projectId={zeroDevOptions.projectId}>
        <PrivyProvider
          appId={Config.VITE_PRIVY_APP_ID!}
          onSuccess={(args) => {
            const email = args.email?.address || args.google?.email
            if (email) {
              Mixpanel.identify(email)
              Sentry.setUser({ email })
            }
            window.location.assign("/")
          }}
          config={{
            embeddedWallets: {
              createOnLogin: "all-users",
              noPromptOnSignature: true,
              requireUserPasswordOnCreate: false,
            },
            defaultChain: mainnet,
            supportedChains: [mainnet],
          }}
        >
          <RelayEnvironmentProvider environment={RelayEnvironment}>
            <ThemeProvider attribute="class" defaultTheme="light">
              <Analytics />
              <Suspense>
                <Toaster />
                <NavbarProvider>
                  <RouterProvider router={createRouter()} />
                </NavbarProvider>
              </Suspense>
            </ThemeProvider>
          </RelayEnvironmentProvider>
        </PrivyProvider>
      </ZeroDevProvider>,
    )
  })
  .catch((err) => {
    Sentry.captureException(err)
    console.error(err)
    root.render(
      <React.StrictMode>
        <ThemeProvider attribute="class">
          <Analytics />
          <div className="h-[100vh] w-[100vw] text-center">
            Something wrong has happened, please refresh the page
          </div>
        </ThemeProvider>
      </React.StrictMode>,
    )
  })
