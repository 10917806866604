/**
 * @generated SignedSource<<e65746e47778b6f8414ab27e7946bdfc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type categoryCell_BankTransferCategoryFragment$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"categoryCell_CategoryFragment">;
  readonly " $fragmentType": "categoryCell_BankTransferCategoryFragment";
}>;
export type categoryCell_BankTransferCategoryFragment$key = ReadonlyArray<{
  readonly " $data"?: categoryCell_BankTransferCategoryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"categoryCell_BankTransferCategoryFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "categoryCell_BankTransferCategoryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "categoryCell_CategoryFragment"
    }
  ],
  "type": "Category",
  "abstractKey": null
};

(node as any).hash = "b30be21cea96d938a8ad27b973c55f01";

export default node;
