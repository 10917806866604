import { useCallback } from "react"
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete"
import { useFormContext } from "react-hook-form"

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Heading,
  Input,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Separator,
  Text,
} from "@utopia/ui"

import type { AddBankAccountData } from "./add-bank-account-schema.js"

type AddressComponent = {
  long_name: string
  short_name: string
  types: string[]
}

// Utility function to extract an address component based on a type
const getAddressComponent = (
  addressComponents: AddressComponent[],
  type: string,
  attribute: keyof Omit<AddressComponent, "types"> = "long_name",
) => {
  const component = addressComponents.find((c) => c.types.includes(type))
  return component ? component[attribute] : ""
}

export const BankAccountForm = () => {
  const { setValue, control, watch } = useFormContext<AddBankAccountData>()

  const handleAddressChange = useCallback(
    (address: { value: { place_id: string } } | null) => {
      if (!address) {
        // Clear all autocompleted form inputs
        setValue("fullAddress", "")
        setValue("addressLine1", "")
        setValue("addressLine2", "")
        setValue("city", "")
        setValue("state", "")
        setValue("zip", "")
        return
      }
      void geocodeByPlaceId(address.value.place_id).then((results) => {
        const addressComponents = results[0].address_components
        if (addressComponents) {
          const addressLine1 = [
            getAddressComponent(addressComponents, "street_number"),
            getAddressComponent(addressComponents, "route"),
          ].join(" ")

          const addressLine2 = getAddressComponent(addressComponents, "subpremise")

          const city =
            getAddressComponent(addressComponents, "locality") ||
            getAddressComponent(addressComponents, "postal_town")
          const state = getAddressComponent(
            addressComponents,
            "administrative_area_level_1",
            "short_name",
          )
          const zip =
            getAddressComponent(addressComponents, "postal_code") +
            getAddressComponent(addressComponents, "postal_code_suffix")

          setValue("fullAddress", results[0].formatted_address)
          setValue("addressLine1", addressLine1, {
            shouldValidate: true,
          })
          setValue("addressLine2", addressLine2, {
            shouldValidate: true,
          })
          setValue("city", city, { shouldValidate: true })
          setValue("state", state, {
            shouldValidate: true,
          })
          setValue("zip", zip, { shouldValidate: true })
        }
      })
    },
    [setValue],
  )

  const country = watch("country")

  const countryOptions = [
    { value: "USA", label: "USA" },
    { value: "Other", label: "Other" },
  ]

  return (
    <div className="grid space-y-4 overflow-auto py-4">
      <Heading className="font-medium">Account owner details</Heading>
      <FormField
        control={control}
        name="accountOwnerName"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-normal">Full legal name</FormLabel>
            <FormControl>
              <Input
                className="rounded-r-none"
                {...field}
                placeholder="Enter full legal name"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="country"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-normal">Country</FormLabel>
            <Select onValueChange={field.onChange} defaultValue={field.value}>
              <FormControl>
                <SelectTrigger className="w-full">
                  <SelectValue>
                    {field.value || <Text variant="lighter">Select country</Text>}
                  </SelectValue>
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                <SelectGroup>
                  {countryOptions.map((countryOption) => (
                    <SelectItem key={countryOption.value} value={countryOption.value}>
                      {countryOption.label}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />

      {country === "USA" && (
        <FormField
          control={control}
          name="fullAddress"
          render={({ field: { value, name } }) => (
            <FormItem>
              <FormLabel className="font-normal">Address</FormLabel>
              <FormControl>
                <GooglePlacesAutocomplete
                  apiKey={import.meta.env.VITE_GOOGLE_PLACES_API_KEY}
                  autocompletionRequest={{
                    componentRestrictions: { country: ["us"] },
                  }}
                  selectProps={{
                    styles: {
                      option: (provided) => ({
                        ...provided,
                        fontSize: "14px",
                      }),
                      control: (base) => ({
                        ...base,
                        border: "1px outline",
                        boxShadow: "0",
                        // borderColor: theme.extend.colors.stone[200],
                        // "&:hover": {
                        //   color: theme.extend.colors.stone[200],
                        // },
                        paddingLeft: "4px",
                        height: "52px",
                        fontSize: "14px",
                      }),
                    },
                    name,
                    value: value ? [{ label: value, value }] : undefined,
                    onChange: handleAddressChange,
                    isClearable: true,
                    noOptionsMessage: () => null,
                    placeholder: <Text className="px-1">Enter an address</Text>,
                    components: {
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    },
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
      <Separator />

      <Heading className="font-medium">Bank account details</Heading>

      <FormField
        control={control}
        name="bankName"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-normal">Bank name</FormLabel>
            <FormControl>
              <Input {...field} placeholder="Enter bank name" />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <div className="u-sm:grid u-sm:grid-flow-col u-sm:space-x-4 u-sm:gap-0 flex flex-col gap-2">
        <FormField
          control={control}
          name="accountNumber"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">Account number</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Enter account number" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="routingNumber"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">ACH routing number</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Enter account number" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  )
}
