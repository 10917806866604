import { useCallback } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { Button, Heading, Text } from "@utopia/ui"

import { personaVerificationSteps } from "#utils/bridge.js"
import { Mixpanel, NexusEvent } from "#utils/mixpanel.js"

import type { personaVerification_WorkspaceFragment$key } from "./__generated__/personaVerification_WorkspaceFragment.graphql"
import { ContactSupportLink } from "./contact-support-link.js"

const copyTextOptions = {
  individual: {
    heading: "You’ll need to verify your identity with Persona.",
    description: (
      <Text size="lg">
        You’ll be asked to provide your ID and a few other details about yourself. For
        additional help, please <ContactSupportLink />.
      </Text>
    ),
    submit: "Start verifying your identity",
  },
  business: {
    heading: "You’ll need to finish these steps to verify your business.",
    description: (
      <Text size="lg">
        For additional help, please <ContactSupportLink />.
      </Text>
    ),
    submit: "Start by verifying your legal entity",
  },
}

const WorkspaceFragment = graphql`
  fragment personaVerification_WorkspaceFragment on Workspace {
    workspaceType
    kycStatus @required(action: THROW) {
      kycUrl
    }
  }
`

interface PersonaVerificationProps {
  workspace: personaVerification_WorkspaceFragment$key
}

export const PersonaVerification = ({ workspace }: PersonaVerificationProps) => {
  const workspaceData = useFragment(WorkspaceFragment, workspace)
  const copyText = copyTextOptions[workspaceData.workspaceType]

  const onVerifyKYC = useCallback(() => {
    Mixpanel.track(NexusEvent.verification_launchedPersona, {
      location: "dashboard",
    })
    window.open(workspaceData.kycStatus.kycUrl, "_blank")
  }, [workspaceData.kycStatus.kycUrl])

  return (
    <div className="flex flex-col">
      <Heading size="md">{copyText.heading}</Heading>
      <Text className="mt-2">{copyText.description}</Text>
      {workspaceData.workspaceType === "business" && (
        <div className="mt-6 grid w-full rounded-md border">
          {personaVerificationSteps.map((step, index) => (
            <div key={index} className="grid grid-cols-[auto_1fr] gap-4 border-b p-4">
              <div className="flex h-5 w-5 items-center justify-center rounded-full">
                <span className="text-xs">{++index}</span>
              </div>
              <div className="grid gap-1">
                <Text className="font-semibold">{step.title}</Text>
                <Text variant="lighter">{step.description}</Text>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="mt-6 grid">
        <Button className="w-full" onClick={onVerifyKYC}>
          {copyText.submit}
        </Button>
        <Text className="mt-4 text-center" variant="lighter" size="xs">
          Due to technical constraints, this button may appear even after verifying your
          legal entity.
        </Text>
      </div>
    </div>
  )
}
