import { useParams } from "@tanstack/react-router"
import { graphql, useLazyLoadQuery } from "react-relay"

import {
  Badge,
  FormControl,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text,
} from "@utopia/ui"

import type { categoryFormSelect_CategoriesQuery } from "./__generated__/categoryFormSelect_CategoriesQuery.graphql"

const CategoriesQuery = graphql`
  query categoryFormSelect_CategoriesQuery($workspaceSlug: String!) {
    categories(workspaceSlug: $workspaceSlug) {
      id
      name
    }
  }
`

interface CategoryFormSelectProps {
  value: string | undefined
  onChange: (value: string) => void
}

export const CategoryFormSelect = ({ value, onChange }: CategoryFormSelectProps) => {
  const { slug: workspaceSlug } = useParams({
    from: "/authenticated-route/$slug",
  })

  const data = useLazyLoadQuery<categoryFormSelect_CategoriesQuery>(CategoriesQuery, {
    workspaceSlug,
  })

  return (
    <Select value={value} onValueChange={onChange}>
      <FormControl>
        <SelectTrigger className="rounded-b-none p-4">
          <SelectValue>
            {value ? (
              <CategoryBadge
                name={data.categories.find((c) => c.id === value)?.name ?? ""}
              />
            ) : (
              <Text className="text-stone-400">Select a category</Text>
            )}
          </SelectValue>
        </SelectTrigger>
      </FormControl>
      <SelectContent>
        <SelectGroup>
          {data.categories.map((category) => (
            <SelectItem key={category.id} value={category.id}>
              <CategoryBadge name={category.name} />
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}

const CategoryBadge = ({ name }: { name: string }) => (
  <Badge>
    <Text>{name}</Text>
  </Badge>
)
