import { useCallback } from "react"

import { Button, Heading, Text, useToast } from "@utopia/ui"

import { useAuthenticatedUserInfo } from "#store/user-info.js"

export const EmptyContactsPage = ({ onClick }: { onClick: () => void }) => {
  const {
    me: {
      user: { workspaces },
    },
  } = useAuthenticatedUserInfo()

  const { toast } = useToast()

  const addContacts = useCallback(() => {
    if (!workspaces[0].customerId) {
      toast({
        description:
          "Workspace not verified. Please verify your workspace before adding contacts",
      })
    } else {
      onClick()
    }
  }, [onClick, toast, workspaces])

  return (
    <div className="flex h-full flex-col items-center justify-center gap-2">
      <img
        src="/empty-states/contacts.png"
        className="h-[156px] w-[172px]"
        alt="Utopia logo"
      />
      <Heading size="md" className="text-center">
        You haven’t added any contacts.
      </Heading>
      <Text size="md" className="text-center">
        Once you’ve added contacts, you can send them USD transfers.
      </Text>
      <Button onClick={addContacts} className="mt-2 w-[240px]">
        Add Contacts
      </Button>
    </div>
  )
}
