import { useCallback } from "react"
import { v4 as uuidv4 } from "uuid"

import { useToastAndError } from "#hooks/use-toast-and-error.js"
import type { BankAccount, BankAccountData } from "#pages/contacts/index.js"
import { Mixpanel } from "#utils/mixpanel.js"

import { BankAccountDialog } from "./bank-account-dialog.js"

interface AddBankAccountDialogProps {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  stagedBankAccounts: BankAccount[] | null
  setStagedBankAccounts: (bankAccounts: BankAccount[]) => void
}

export const AddBankAccountDialog = ({
  isOpen,
  onOpenChange,
  stagedBankAccounts,
  setStagedBankAccounts,
}: AddBankAccountDialogProps) => {
  const toastAndError = useToastAndError()

  const onSubmit = useCallback(
    async (data: BankAccountData) => {
      try {
        const input = {
          idempotencyKey: uuidv4(),
          ...(data.country === "USA"
            ? {
                address: {
                  city: data.city,
                  country: data.country,
                  postalCode: data.zip,
                  state: data.state,
                  streetLine1: data.addressLine1,
                  streetLine2: data.addressLine2 || "",
                },
              }
            : {}),
          accountNumber: data.accountNumber,
          routingNumber: data.routingNumber,
          bankName: data.bankName,
          accountOwnerName: data.accountOwnerName,
          accountName: data.accountName,
        }

        setStagedBankAccounts([...(stagedBankAccounts || []), input])
        Mixpanel.track("nexus:bankAccount_added")
      } catch (error) {
        toastAndError("Error creating bank account", error)
        if (error instanceof Error) {
          Mixpanel.track("nexus:bankAccount_failed", {
            message: error.message,
          })
        } else {
          Mixpanel.track("nexus:bankAccount_failed")
        }
      }
    },
    [stagedBankAccounts, setStagedBankAccounts, toastAndError],
  )

  return (
    <BankAccountDialog
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      onSubmit={onSubmit}
      currentBankAccount={null}
      setCurrentBankAccount={() => {}}
    />
  )
}
