import { chainIdToUsdcAddress, chainIdToUsdtAddress } from "@utopia/evm"

export function formatAmount(amountInCents: string) {
  if (amountInCents.length > 2) {
    const dollarsPart = amountInCents.slice(0, amountInCents.length - 2)
    const centsPart = amountInCents.slice(amountInCents.length - 2)
    return `${dollarsPart}.${centsPart}`
  }

  if (amountInCents.length === 2) {
    return `0.${amountInCents}`
  }

  if (amountInCents.length === 1) {
    return `0.0${amountInCents}`
  }

  return "0.00"
}

export function formatToken({
  tokenAddress,
  networkId,
}: {
  tokenAddress: string
  networkId: number
}) {
  if (chainIdToUsdcAddress[networkId].toLowerCase() === tokenAddress.toLowerCase()) {
    return {
      logoSrc: "/usdc.svg",
      symbol: "USDC",
    }
  }

  if (chainIdToUsdtAddress[networkId].toLowerCase() === tokenAddress.toLowerCase()) {
    return {
      logoSrc: "/usdt.svg",
      symbol: "USDT",
    }
  }

  return null
}
