import { zodResolver } from "@hookform/resolvers/zod"
import { useState } from "react"
import { useForm } from "react-hook-form"

import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  Heading,
  Separator,
} from "@utopia/ui"

import { useToastAndError } from "#hooks/use-toast-and-error.js"
import { AddBankAccountDialog } from "#pages/contacts/bank-accounts/components/add-bank-account-dialog.js"
import { EditBankAccountDialog } from "#pages/contacts/bank-accounts/components/edit-bank-account-dialog.js"

import type { BankAccount, ExistingBankAccount } from "../index.js"
import type { AddContactData } from "./add-contact-schema.js"
import { addContactSchema } from "./add-contact-schema.js"
import { ContactForm } from "./contact-form.js"

interface ContactDialogProps {
  isOpen: boolean
  stagedContact: AddContactData | null
  bankAccounts?: ExistingBankAccount[]
  stagedBankAccounts: BankAccount[] | null
  onOpenChange: (open: boolean) => void
  setStagedContact: (contact: AddContactData | null) => void
  setStagedBankAccounts: (bankAccounts: BankAccount[] | null) => void
  onSubmit: (data: AddContactData) => Promise<void>
}

export const ContactDialog = ({
  isOpen,
  stagedContact,
  bankAccounts,
  stagedBankAccounts,
  onOpenChange,
  setStagedContact,
  setStagedBankAccounts,
  onSubmit,
}: ContactDialogProps) => {
  const [addBankAccountOpen, setAddBankAccountOpen] = useState(false)
  const [editBankAccountOpen, setEditBankAccountOpen] = useState(false)
  const [currentBankAccount, setCurrentBankAccount] = useState<BankAccount | null>(null)

  const form = useForm<AddContactData>({
    resolver: zodResolver(addContactSchema),
    mode: "onChange",
    defaultValues: {
      name: stagedContact?.name || "",
      email: stagedContact?.email || "",
      bankAccounts: stagedBankAccounts || undefined,
    },
  })

  const toastAndError = useToastAndError()

  const onClickSubmit = form.handleSubmit(async (data) => {
    try {
      await onSubmit(data)
      handleModalClose()
    } catch (error) {
      toastAndError("Failed to save contact", error)
    }
  })

  const handleModalClose = () => {
    setStagedBankAccounts(null)
    setCurrentBankAccount(null)
    setStagedContact(null)
    onOpenChange(false)
  }

  const handleAddBankAccount = (open: boolean) => {
    setAddBankAccountOpen(open)
  }

  const handleEditBankAccount = (open: boolean) => {
    setEditBankAccountOpen(open)
  }

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(state) => {
        if (!state) {
          handleModalClose()
        }
      }}
    >
      <DialogContent className="u-md:min-w-[600px] u-md:p-0 gap-4 pb-4">
        <Form {...form}>
          <form onSubmit={onClickSubmit}>
            <DialogHeader className="p-4">
              <DialogTitle>
                <Heading size="md">
                  {stagedContact?.id ? "Edit contact." : "Add contact."}
                </Heading>
              </DialogTitle>
            </DialogHeader>
            <Separator />
            <div className="px-4">
              <ContactForm
                stagedBankAccounts={stagedBankAccounts}
                handleAddBankAccount={handleAddBankAccount}
                handleEditBankAccount={handleEditBankAccount}
                existingBankAccounts={bankAccounts}
                setStagedBankAccounts={setStagedBankAccounts}
                setCurrentBankAccount={setCurrentBankAccount}
                stagedContact={stagedContact}
                setStagedContact={setStagedContact}
              />
            </div>
            <Separator />
            <DialogFooter className="u-md:justify-between h-[84px] p-4">
              <Button
                type="button"
                variant="outline"
                onClick={async () => {
                  handleModalClose()
                }}
              >
                Close
              </Button>
              <Button type="submit" isLoading={form.formState.isSubmitting}>
                {stagedContact?.id ? "Save" : "Add contact"}
              </Button>
            </DialogFooter>
          </form>
        </Form>
        {addBankAccountOpen && (
          <AddBankAccountDialog
            isOpen={addBankAccountOpen}
            onOpenChange={handleAddBankAccount}
            stagedBankAccounts={stagedBankAccounts}
            setStagedBankAccounts={setStagedBankAccounts}
          />
        )}
        {editBankAccountOpen && (
          <EditBankAccountDialog
            isOpen={editBankAccountOpen}
            onOpenChange={handleEditBankAccount}
            currentBankAccount={currentBankAccount}
            stagedBankAccounts={stagedBankAccounts}
            setStagedBankAccount={setStagedBankAccounts}
            setCurrentBankAccount={setCurrentBankAccount}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
