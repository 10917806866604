import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { CategoryCell as CategoryCellBase } from "#components/table/category-cell.js"

import type { categoryCell_BankTransferCategoryFragment$key } from "./__generated__/categoryCell_BankTransferCategoryFragment.graphql"
import type { categoryCell_BankTransferFragment$key } from "./__generated__/categoryCell_BankTransferFragment.graphql"

const BankTransferFragment = graphql`
  fragment categoryCell_BankTransferFragment on OfframpTransfer {
    categoryId
  }
`

const CategoriesFragment = graphql`
  fragment categoryCell_BankTransferCategoryFragment on Category @relay(plural: true) {
    ...categoryCell_CategoryFragment
  }
`

interface CategoryCellProps {
  bankTransfer: categoryCell_BankTransferFragment$key
  categories: categoryCell_BankTransferCategoryFragment$key
}

export function CategoryCell({ bankTransfer, categories }: CategoryCellProps) {
  const bankTransferData = useFragment(BankTransferFragment, bankTransfer)
  const categoriesData = useFragment(CategoriesFragment, categories)

  return (
    <CategoryCellBase
      categories={categoriesData}
      categoryId={bankTransferData.categoryId}
    />
  )
}
