import MenuIcon from "@mui/icons-material/Menu"

import { Heading } from "@utopia/ui"

import { useNavbar } from "#providers/navbar.js"

interface TopBarProps {
  title: string
}

export function Topbar({ title }: TopBarProps) {
  const { isNavbarOpen, toggleNavbar } = useNavbar()

  return (
    <div className="u-md:border-b-0 u-md:hidden flex h-16 w-full items-center justify-center border-b p-4">
      {!isNavbarOpen && (
        <button className="absolute left-0 w-fit pl-4" onClick={() => toggleNavbar(true)}>
          <MenuIcon fontSize="small" />
        </button>
      )}
      <Heading size="xs">{title}</Heading>
    </div>
  )
}
