import InfoOutlined from "@mui/icons-material/InfoOutlined"
import InlineSVG from "react-inlinesvg"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@utopia/ui"

const keyToDescription = {
  transferAmountInDollars: {
    detail: "Transfer amount",
    tooltip: null,
  },
  transferMethodAmountInDollars: { detail: "Transfer method", tooltip: null },
  utopiaFeeInDollars: {
    detail: "Utopia fee",
    tooltip: (stablecoin: string) =>
      `Utopia charges a 0.5% fee for all USD transfers. If the fee is less than 1.00 ${stablecoin}, we will charge 1.00 ${stablecoin} instead.`,
  },
}

interface PaymentBreakDown {
  transferAmountInDollars?: string
  transferMethodAmountInDollars?: string
  utopiaFeeInDollars?: string
  paymentTotalInDollars: string
}

interface PaymentsTableProps {
  paymentBreakDown: PaymentBreakDown
  stablecoin: {
    logoSrc: string
    name: string
  }
}

export const PaymentsTable = ({ paymentBreakDown, stablecoin }: PaymentsTableProps) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-1/3">Detail</TableHead>
          <TableHead className="w-1/3 text-right">USD value</TableHead>
          <TableHead className="w-1/3 text-right">{stablecoin.name} payment</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {(
          [
            "transferAmountInDollars",
            "transferMethodAmountInDollars",
            "utopiaFeeInDollars",
          ] as const
        ).map((key) => (
          <TableRow key={key}>
            <TableCell>
              <div className="flex justify-between">
                {keyToDescription[key].detail}
                {keyToDescription[key].tooltip && (
                  <TooltipProvider>
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger>
                        <InfoOutlined fontSize="small" className="text-stone-400" />
                      </TooltipTrigger>
                      <TooltipContent className="max-w-[240px]">
                        <Text>{keyToDescription[key].tooltip?.(stablecoin.name)}</Text>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                )}
              </div>
            </TableCell>
            <TableCell>
              <div className="flex flex-wrap items-center justify-end">
                {paymentBreakDown[key] ?? "-"}{" "}
                <InlineSVG src="/usd.svg" className="h-5 w-5 p-1" /> USD
              </div>
            </TableCell>
            <TableCell>
              <div className="flex flex-wrap items-center justify-end">
                {paymentBreakDown[key] ?? "-"}{" "}
                <InlineSVG src={stablecoin.logoSrc} className="h-5 w-5 p-1" />{" "}
                {stablecoin.name}
              </div>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell colSpan={3}>
            <div className="flex items-center justify-between">
              <span>Payment total</span>

              <div className="flex items-center justify-end">
                {parseFloat(paymentBreakDown.paymentTotalInDollars) === 0
                  ? "-"
                  : paymentBreakDown.paymentTotalInDollars}{" "}
                <InlineSVG src={stablecoin.logoSrc} className="h-5 w-5 p-1" />{" "}
                {stablecoin.name}
              </div>
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
