import MenuIcon from "@mui/icons-material/Menu"

import { useNavbar } from "#providers/navbar.js"

interface TopBarProps {
  children?: React.ReactNode
}

export function Topbar(props: TopBarProps) {
  const { isNavbarOpen, toggleNavbar } = useNavbar()

  return (
    <div className="flex h-16 w-full border-b">
      {!isNavbarOpen && (
        <button
          className="hamburger-menu u-md:hidden ml-4"
          onClick={() => toggleNavbar(true)}
        >
          <MenuIcon fontSize="small" />
        </button>
      )}
      <div className="flex h-16 w-full flex-row-reverse items-center px-4 py-3">
        {props.children}
      </div>
    </div>
  )
}
