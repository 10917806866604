import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import { useCallback, useState } from "react"
import { graphql, useFragment } from "react-relay"

import { Avatar, AvatarFallback, Button, TableCell, TableRow } from "@utopia/ui"

import { getInitials } from "#utils/get-initials.js"

import type { contactRow_ContactsFragment$key } from "./__generated__/contactRow_ContactsFragment.graphql"
import { ExistingBankAccountDisplay } from "./bank-account-display.js"
import { EditContactDialog } from "./edit-contact-dialog.js"

export const ContactsRowFragment = graphql`
  fragment contactRow_ContactsFragment on Contact {
    id
    name
    email
    bankAccounts {
      id
      bankName
      lastFourDigits
      accountOwnerName
    }
  }
`

interface ContactTableRowProps {
  workspaceSlug: string
  contact: contactRow_ContactsFragment$key
  refetchContacts: VoidFunction
}

interface ExistingBankAccount {
  id: string
  bankName: string
  lastFourDigits: string
  accountOwnerName: string
}

export function ContactTableRow({
  workspaceSlug,
  contact,
  refetchContacts,
}: ContactTableRowProps) {
  const contactData = useFragment(ContactsRowFragment, contact)
  const [editContactFormOpen, setEditContactFormOpen] = useState(false)

  const openEditContactModal = useCallback(() => {
    setEditContactFormOpen(true)
  }, [setEditContactFormOpen])

  const onOpenChange = (isOpen: boolean) => {
    setEditContactFormOpen(isOpen)
  }

  const bankAccounts = contactData.bankAccounts || []

  return (
    <TableRow key={contactData.id}>
      <TableCell className="!align-top">
        <div className="flex gap-4">
          <Avatar>
            <AvatarFallback>{getInitials(contactData.name)}</AvatarFallback>
          </Avatar>
          <div className="pt-2">{contactData.name}</div>
        </div>
      </TableCell>
      <TableCell className="!align-top">
        <div className="flex gap-4">{contactData.email}</div>
      </TableCell>

      {bankAccounts[0] ? (
        <TableCell className="!align-top">
          <div className="flex flex-col gap-4">
            <ExistingBankAccountDisplay bankAccount={bankAccounts[0]} />
            {bankAccounts.length > 1 && (
              <Button
                variant="outline"
                colorScheme="stone"
                size="sm"
                onClick={openEditContactModal}
              >
                {`View ${bankAccounts.length - 1} more`}
              </Button>
            )}
          </div>
        </TableCell>
      ) : (
        <TableCell />
      )}
      <TableCell>
        <div className="flex justify-center">
          <EditOutlinedIcon
            className="cursor-pointer"
            fontSize="small"
            onClick={openEditContactModal}
          />
        </div>
      </TableCell>
      {editContactFormOpen && (
        <EditContactDialog
          workspaceSlug={workspaceSlug}
          refetchContacts={refetchContacts}
          isOpen={editContactFormOpen}
          onOpenChange={onOpenChange}
          contact={contactData}
          bankAccounts={bankAccounts as ExistingBankAccount[]}
        />
      )}
    </TableRow>
  )
}
