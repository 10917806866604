/**
 * @generated SignedSource<<b2e4279d2ca7651014ae0737f211df47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OfframpTransferEditMetadataInput = {
  categoryId?: string | null | undefined;
  description?: string | null | undefined;
  id: string;
  workspaceSlug: string;
};
export type usdTransferReviewDialog_OfframpTransferEditMetadataMutation$variables = {
  input: OfframpTransferEditMetadataInput;
};
export type usdTransferReviewDialog_OfframpTransferEditMetadataMutation$data = {
  readonly offrampTransferEditMetadata: {
    readonly offrampTransfer: {
      readonly categoryId: string | null | undefined;
      readonly description: string | null | undefined;
      readonly id: string;
    };
  };
};
export type usdTransferReviewDialog_OfframpTransferEditMetadataMutation = {
  response: usdTransferReviewDialog_OfframpTransferEditMetadataMutation$data;
  variables: usdTransferReviewDialog_OfframpTransferEditMetadataMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "OfframpTransferEditMetadataPayload",
    "kind": "LinkedField",
    "name": "offrampTransferEditMetadata",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OfframpTransfer",
        "kind": "LinkedField",
        "name": "offrampTransfer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "categoryId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usdTransferReviewDialog_OfframpTransferEditMetadataMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usdTransferReviewDialog_OfframpTransferEditMetadataMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "11735384061628076f6dcbf332471884",
    "id": null,
    "metadata": {},
    "name": "usdTransferReviewDialog_OfframpTransferEditMetadataMutation",
    "operationKind": "mutation",
    "text": "mutation usdTransferReviewDialog_OfframpTransferEditMetadataMutation(\n  $input: OfframpTransferEditMetadataInput!\n) {\n  offrampTransferEditMetadata(input: $input) {\n    offrampTransfer {\n      id\n      categoryId\n      description\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "185897864f6c8bfde82cc16b390bcdf8";

export default node;
