/**
 * @generated SignedSource<<5c283331414daf6493bcccfa206fff76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contactsTable_ContactsFragment$data = ReadonlyArray<{
  readonly bankAccounts: ReadonlyArray<{
    readonly bankName: string;
  }>;
  readonly email: string | null | undefined;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"contactRow_ContactsFragment">;
  readonly " $fragmentType": "contactsTable_ContactsFragment";
}>;
export type contactsTable_ContactsFragment$key = ReadonlyArray<{
  readonly " $data"?: contactsTable_ContactsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"contactsTable_ContactsFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "contactsTable_ContactsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccount",
      "kind": "LinkedField",
      "name": "bankAccounts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bankName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contactRow_ContactsFragment"
    }
  ],
  "type": "Contact",
  "abstractKey": null
};

(node as any).hash = "718cf46b1239b72ee0a878f309a023f6";

export default node;
