/**
 * @generated SignedSource<<bb4c6c73e2874c1c114eb137d1021a0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type categoryCell_CategoryFragment$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "categoryCell_CategoryFragment";
}>;
export type categoryCell_CategoryFragment$key = ReadonlyArray<{
  readonly " $data"?: categoryCell_CategoryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"categoryCell_CategoryFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "categoryCell_CategoryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Category",
  "abstractKey": null
};

(node as any).hash = "1784913d0ebd26053bb60965bebb9f9b";

export default node;
