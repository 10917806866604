/**
 * @generated SignedSource<<9ba21aa4433fef1892b88c8358abfa22>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type bankTransfers_CategoriesQuery$variables = {
  workspaceSlug: string;
};
export type bankTransfers_CategoriesQuery$data = {
  readonly categories: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"bankTransfersTable_CategoryFragment">;
  }>;
};
export type bankTransfers_CategoriesQuery = {
  response: bankTransfers_CategoriesQuery$data;
  variables: bankTransfers_CategoriesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workspaceSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "workspaceSlug",
    "variableName": "workspaceSlug"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bankTransfers_CategoriesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Category",
        "kind": "LinkedField",
        "name": "categories",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "bankTransfersTable_CategoryFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bankTransfers_CategoriesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Category",
        "kind": "LinkedField",
        "name": "categories",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "af8c2a620e12c7b40c547b262899cfb6",
    "id": null,
    "metadata": {},
    "name": "bankTransfers_CategoriesQuery",
    "operationKind": "query",
    "text": "query bankTransfers_CategoriesQuery(\n  $workspaceSlug: String!\n) {\n  categories(workspaceSlug: $workspaceSlug) {\n    ...bankTransfersTable_CategoryFragment\n  }\n}\n\nfragment bankTransfersTable_CategoryFragment on Category {\n  ...categoryCell_BankTransferCategoryFragment\n}\n\nfragment categoryCell_BankTransferCategoryFragment on Category {\n  ...categoryCell_CategoryFragment\n}\n\nfragment categoryCell_CategoryFragment on Category {\n  id\n  name\n}\n"
  }
};
})();

(node as any).hash = "66bed78867a2c8ad51bcb8443e50f064";

export default node;
