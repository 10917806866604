// currently just copied from chakra's implementation for avatar names here:
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/avatar/src/avatar-name.tsx
export function getInitials(name: string) {
  const names = name.split(" ")
  const firstName = names[0] ?? ""
  const lastName = names.length > 1 ? names[names.length - 1] : ""
  return firstName && lastName
    ? `${firstName.charAt(0)}${lastName.charAt(0)}`
    : firstName.charAt(0)
}
