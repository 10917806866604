import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { Avatar, AvatarFallback, TableCell, Text } from "@utopia/ui"

import { getInitials } from "#utils/get-initials.js"

import type { recipientCell_BankTransferFragment$key } from "./__generated__/recipientCell_BankTransferFragment.graphql"

const BankTransferFragment = graphql`
  fragment recipientCell_BankTransferFragment on OfframpTransfer {
    bankAccount {
      accountOwnerName
      bankName
    }
  }
`

interface BankTransfersTableProps {
  bankTransfer: recipientCell_BankTransferFragment$key
}

export function RecipientCell({ bankTransfer }: BankTransfersTableProps) {
  const bankTransferData = useFragment(BankTransferFragment, bankTransfer)

  return (
    <TableCell>
      <div className="flex gap-2">
        <Avatar>
          <AvatarFallback>
            {getInitials(bankTransferData.bankAccount.accountOwnerName)}
          </AvatarFallback>
        </Avatar>
        <div className="flex flex-col">
          <Text>{bankTransferData.bankAccount.accountOwnerName}</Text>
          <Text size="xs" variant="lighter">
            {bankTransferData.bankAccount.bankName}
          </Text>
        </div>
      </div>
    </TableCell>
  )
}
