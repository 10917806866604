import { useNavigate, useParams } from "@tanstack/react-router"
import { useCallback } from "react"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

import {
  Button,
  FormControl,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@utopia/ui"

import type { bankAccountsFormSelect_BankAccountsQuery } from "./__generated__/bankAccountsFormSelect_BankAccountsQuery.graphql"
import { BankAccountWithRecipientDisplay } from "./bank-account-display.js"

const BankAccountsQuery = graphql`
  query bankAccountsFormSelect_BankAccountsQuery($workspaceSlug: String!) {
    bankAccounts(workspaceSlug: $workspaceSlug) {
      id
      accountOwnerName
      ...bankAccountDisplay_BankAccountFragment
    }
  }
`

interface BankAccountsFormSelectProps {
  value: string | undefined
  onChange: (value: string) => void
  disabled?: boolean
}

export const BankAccountsFormSelect = ({
  value,
  onChange,
  disabled,
}: BankAccountsFormSelectProps) => {
  const { slug: workspaceSlug } = useParams({
    from: "/authenticated-route/$slug",
  })

  const data = useLazyLoadQuery<bankAccountsFormSelect_BankAccountsQuery>(
    BankAccountsQuery,
    {
      workspaceSlug,
    },
  )

  const navigate = useNavigate()

  const handleAddContact = useCallback(async () => {
    await navigate({
      to: "/$slug/contacts",
      params: { slug: workspaceSlug },
      search: { addContactForTransfer: true },
    })
  }, [navigate, workspaceSlug])

  const selectedBankAccount = data.bankAccounts.find(
    (bankAccount) => bankAccount.id === value,
  )

  return (
    <Select value={value} onValueChange={onChange}>
      <FormControl>
        <SelectTrigger className="w-full p-4" disabled={disabled}>
          <SelectValue>
            {selectedBankAccount ? (
              <BankAccountWithRecipientDisplay bankAccount={selectedBankAccount} />
            ) : (
              "Select a contact"
            )}
          </SelectValue>
        </SelectTrigger>
      </FormControl>

      <SelectContent className="max-h-[300px] overflow-auto">
        <SelectGroup>
          {data.bankAccounts.map((bankAccount) => (
            <SelectItem key={bankAccount.id} value={bankAccount.id}>
              <BankAccountWithRecipientDisplay bankAccount={bankAccount} />
            </SelectItem>
          ))}
        </SelectGroup>
        <Button
          variant="ghost"
          className="flex w-full justify-start"
          onClick={handleAddContact}
        >
          + Add contact
        </Button>
      </SelectContent>
    </Select>
  )
}
