import { useCallback } from "react"

import { useToastAndError } from "#hooks/use-toast-and-error.js"
import type { BankAccount, BankAccountData } from "#pages/contacts/index.js"
import { Mixpanel } from "#utils/mixpanel.js"

import { BankAccountDialog } from "./bank-account-dialog.js"

interface EditBankAccountDialogProps {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  currentBankAccount: BankAccount | null
  stagedBankAccounts: BankAccount[] | null
  setCurrentBankAccount: (bankAccount: BankAccount | null) => void
  setStagedBankAccount: (bankAccount: BankAccount[] | null) => void
}

export const EditBankAccountDialog = ({
  isOpen,
  onOpenChange,
  currentBankAccount,
  stagedBankAccounts,
  setStagedBankAccount,
  setCurrentBankAccount,
}: EditBankAccountDialogProps) => {
  const toastAndError = useToastAndError()

  const onSubmit = useCallback(
    async (data: BankAccountData) => {
      try {
        if (!currentBankAccount) {
          throw new Error("No bank account to update")
        }

        const idempotencyKey = currentBankAccount.idempotencyKey

        const input: BankAccount = {
          ...data,
          idempotencyKey,
          address:
            data.country === "USA"
              ? {
                  city: data.city,
                  country: data.country,
                  postalCode: data.zip,
                  state: data.state,
                  streetLine1: data.addressLine1,
                  streetLine2: data.addressLine2,
                }
              : undefined,
        }

        const updatedBankAccounts = stagedBankAccounts
          ? stagedBankAccounts.map((account) =>
              account.idempotencyKey === idempotencyKey ? input : account,
            )
          : [input]

        setStagedBankAccount(updatedBankAccounts)
        setCurrentBankAccount(null)
        Mixpanel.track("nexus:bankAccount_edited")
      } catch (error) {
        toastAndError("Error updating bank account", error)
        if (error instanceof Error) {
          Mixpanel.track("nexus:bankAccount_update_failed", {
            message: error.message,
          })
        } else {
          Mixpanel.track("nexus:bankAccount_update_failed")
        }
      }
    },
    [
      currentBankAccount,
      stagedBankAccounts,
      setStagedBankAccount,
      toastAndError,
      setCurrentBankAccount,
    ],
  )

  return (
    <BankAccountDialog
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      onSubmit={onSubmit}
      currentBankAccount={currentBankAccount}
      setCurrentBankAccount={setCurrentBankAccount}
    />
  )
}
