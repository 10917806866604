import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import SearchIcon from "@mui/icons-material/Search"
import { useDebouncedCallback } from "@react-hookz/web"
import { useNavigate, useParams } from "@tanstack/react-router"
import { useState, useTransition } from "react"
import { useFragment, usePaginationFragment } from "react-relay"
import { graphql } from "relay-runtime"

import {
  Button,
  Input,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
  Text,
} from "@utopia/ui"

import { Loading } from "#components/loading.js"
import { Mixpanel, NexusEvent } from "#utils/mixpanel.js"

import type { bankTransfersTable_BankTransferFragment$key } from "./__generated__/bankTransfersTable_BankTransferFragment.graphql"
import type { bankTransfersTable_CategoryFragment$key } from "./__generated__/bankTransfersTable_CategoryFragment.graphql"
import { ActionsCell } from "./actions-cell.js"
import { CategoryCell } from "./category-cell.js"
import { DescriptionCell } from "./description-cell.js"
import { EmptyBankTransfers } from "./empty-state.js"
import { PaymentTotalCell } from "./payment-total-cell.js"
import { RecipientCell } from "./recipient-cell.js"
import { SentAtCell } from "./sent-at-cell.js"
import { StatusCell } from "./status-cell.js"
import { TransferAmountCell } from "./transfer-amount-cell.js"

const BankTransfersConnectionFragment = graphql`
  fragment bankTransfersTable_BankTransferFragment on Query
  @argumentDefinitions(
    after: { type: "String" }
    before: { type: "String" }
    first: { type: "Int" }
    last: { type: "Int" }
    input: { type: "OfframpTransferSearchInput!" }
  )
  @refetchable(queryName: "bankTransfersTable_BankTransfersQuery") {
    offrampTransfers(
      after: $after
      before: $before
      first: $first
      last: $last
      input: $input
    ) @connection(key: "bankTransfersTable_BankTransfersFragment_offrampTransfers") {
      edges {
        node {
          id
          ...recipientCell_BankTransferFragment
          ...transferAmountCell_BankTransferFragment
          ...paymentTotalCell_BankTransferFragment
          ...categoryCell_BankTransferFragment
          ...descriptionCell_BankTransferFragment
          ...sentAtCell_BankTransferFragment
          ...statusCell_BankTransferFragment
          ...actionsCell_BankTransferFragment
        }
      }
    }
  }
`

const CategoriesFragment = graphql`
  fragment bankTransfersTable_CategoryFragment on Category @relay(plural: true) {
    ...categoryCell_BankTransferCategoryFragment
  }
`

interface BankTransfersTableProps {
  maxRowsToShow?: number
  isSearchable?: boolean
  bankTransfers: bankTransfersTable_BankTransferFragment$key
  categories: bankTransfersTable_CategoryFragment$key
}

export function BankTransfersTable({
  maxRowsToShow,
  isSearchable = true,
  bankTransfers,
  categories,
}: BankTransfersTableProps) {
  const [isLoadingSearch, startTransition] = useTransition()
  const navigate = useNavigate()
  const { slug } = useParams({
    from: "/authenticated-route/$slug",
  })

  const onSendBankTransferClick = async () => {
    Mixpanel.track(NexusEvent.bankTransfers_started)
    await navigate({ to: "/$slug/bank-transfers/new", params: { slug } })
  }

  const {
    data: bankTransfersData,
    loadNext,
    refetch,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(BankTransfersConnectionFragment, bankTransfers)
  const categoriesData = useFragment(CategoriesFragment, categories)

  const [searchTerm, setSearchTerm] = useState<string | undefined>()
  const refetchSearch = useDebouncedCallback(
    (newSearchTerm: string) => {
      startTransition(() => {
        refetch({
          input: {
            workspaceSlug: slug,
            searchTerm: newSearchTerm || undefined,
          },
        })
      })
    },
    [refetch],
    500,
  )
  const onSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
    refetchSearch(e.target.value)
  }

  const onShowMore = () => {
    loadNext(20)
  }

  const edges = bankTransfersData.offrampTransfers.edges
    .filter((edge) => !!edge?.node)
    .slice(0, maxRowsToShow)

  return edges.length || searchTerm !== undefined ? (
    <div className="h-full">
      {isSearchable && (
        <div className=" u-sm:flex-row flex flex-col-reverse gap-4 ">
          <div className="h-13 flex flex-grow items-center gap-2 rounded-lg border px-4">
            {isLoadingSearch ? (
              <div className="h-5 w-5">
                <Loading width={20} height={20} />
              </div>
            ) : (
              <SearchIcon className="text-stone-500" fontSize="small" />
            )}
            <Input
              className="h-5 border-0 px-0 py-4"
              value={searchTerm}
              onChange={onSearchTermChange}
              placeholder="Search by recipient name"
            />
          </div>

          <Button onClick={onSendBankTransferClick}>Send USD transfer</Button>
        </div>
      )}

      <div
        className={
          isSearchable
            ? // subtract the above section height away from max height
              "u-sm:max-h-[calc(100%-52px)] h-full max-h-[calc(100%-52px-52px-16px)] pt-6"
            : undefined
        }
      >
        <Table>
          <TableHeader className="sticky top-0 z-[1]">
            <TableRow>
              <TableHead className="w-[14%]">Recipient</TableHead>
              <TableHead className="w-[14%] text-right">Transfer amount</TableHead>
              <TableHead className="w-[14%] text-right">Payment total</TableHead>
              <TableHead className="w-[14%]">Category</TableHead>
              <TableHead className="w-[14%]">Description</TableHead>
              <TableHead className="w-[14%]">Status</TableHead>
              <TableHead className="w-[14%]">
                <div className="flex items-center justify-between">
                  Date sent
                  <ArrowDownwardIcon fontSize="small" />
                </div>
              </TableHead>
              <TableHead className="w-[2%]" />
            </TableRow>
          </TableHeader>
          <TableBody>
            {edges.map((edge) => {
              const bankTransfer = edge?.node
              if (!bankTransfer) return null

              return (
                <TableRow
                  key={bankTransfer.id}
                  className={!maxRowsToShow ? "[&_td]:!rounded-b-none" : ""}
                >
                  <RecipientCell bankTransfer={bankTransfer} />
                  <TransferAmountCell bankTransfer={bankTransfer} />
                  <PaymentTotalCell bankTransfer={bankTransfer} />
                  <CategoryCell bankTransfer={bankTransfer} categories={categoriesData} />
                  <DescriptionCell bankTransfer={bankTransfer} />
                  <StatusCell bankTransfer={bankTransfer} />
                  <SentAtCell bankTransfer={bankTransfer} />
                  <ActionsCell bankTransfer={bankTransfer} />
                </TableRow>
              )
            })}
          </TableBody>

          {!maxRowsToShow && (
            <TableFooter>
              <TableRow>
                <TableHead className="sticky bottom-0" colSpan={8}>
                  <div className="u-md:justify-end flex items-center gap-1 p-4">
                    <Text>{edges.length} rows shown</Text>

                    {hasNext && (
                      <>
                        <Text>•</Text>
                        <Button
                          className="h-5 p-0 hover:bg-inherit"
                          variant="ghost"
                          isLoading={isLoadingNext}
                          onClick={onShowMore}
                        >
                          Show more
                        </Button>
                      </>
                    )}
                  </div>
                </TableHead>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </div>
    </div>
  ) : (
    <EmptyBankTransfers />
  )
}
