/**
 * @generated SignedSource<<b9d914938913ddc0fa11ee6b427aaa01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type bankTransfersTable_CategoryFragment$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"categoryCell_BankTransferCategoryFragment">;
  readonly " $fragmentType": "bankTransfersTable_CategoryFragment";
}>;
export type bankTransfersTable_CategoryFragment$key = ReadonlyArray<{
  readonly " $data"?: bankTransfersTable_CategoryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"bankTransfersTable_CategoryFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "bankTransfersTable_CategoryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "categoryCell_BankTransferCategoryFragment"
    }
  ],
  "type": "Category",
  "abstractKey": null
};

(node as any).hash = "d86c447839bf750f5fb8d361711a2cd1";

export default node;
