import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"

import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  Heading,
  Separator,
} from "@utopia/ui"

import type { BankAccount, BankAccountData } from "#pages/contacts/index.js"

import { addBankAccountSchema } from "./add-bank-account-schema.js"
import { BankAccountForm } from "./bank-account-form.js"

interface BankAccountDialogProps {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  onSubmit: (data: BankAccountData) => void
  currentBankAccount: BankAccount | null
  setCurrentBankAccount: (bankAccount: BankAccount | null) => void
}

export const BankAccountDialog = ({
  isOpen,
  onOpenChange,
  onSubmit,
  currentBankAccount,
  setCurrentBankAccount,
}: BankAccountDialogProps) => {
  const form = useForm({
    resolver: zodResolver(addBankAccountSchema),
    mode: "onChange",
    defaultValues: {
      idempotencyKey: currentBankAccount?.idempotencyKey || "",
      accountOwnerName: currentBankAccount?.accountOwnerName || "",
      accountNumber: currentBankAccount?.accountNumber || "",
      accountName: currentBankAccount?.accountName || "",
      routingNumber: currentBankAccount?.routingNumber || "",
      fullAddress: "",
      addressLine1: currentBankAccount?.address?.streetLine1 || "",
      addressLine2: currentBankAccount?.address?.streetLine2 || "",
      country: currentBankAccount?.address?.country || "",
      city: currentBankAccount?.address?.city || "",
      state: currentBankAccount?.address?.state || "",
      zip: currentBankAccount?.address?.postalCode || "",
      bankName: currentBankAccount?.bankName || "",
    },
  })

  const onClickSubmit = form.handleSubmit(async (data) => {
    onSubmit(data)
    handleModalClose()
  })

  const handleModalClose = () => {
    setCurrentBankAccount(null)
    onOpenChange(false)
  }

  return (
    <Dialog
      open={isOpen}
      onOpenChange={async (state) => {
        if (!state) {
          handleModalClose()
        }
      }}
    >
      <DialogContent className="u-md:min-w-[600px] u-md:p-0 gap-4 pb-4">
        <Form {...form}>
          <form onSubmit={onClickSubmit}>
            <DialogHeader className="p-4">
              <DialogTitle>
                <Heading size="md">
                  {currentBankAccount?.accountOwnerName
                    ? "Edit bank account"
                    : "Add bank account"}
                </Heading>
              </DialogTitle>
            </DialogHeader>
            <Separator />
            <div className="px-4">
              <BankAccountForm />
            </div>
            <Separator />
            <DialogFooter className="u-md:justify-between h-[84px] items-center p-4">
              <Button
                type="button"
                variant="outline"
                onClick={async () => {
                  handleModalClose()
                }}
              >
                Close
              </Button>
              <Button type="submit" isLoading={form.formState.isSubmitting}>
                {currentBankAccount?.accountOwnerName ? "Save" : "Add bank account"}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
