import * as Sentry from "@sentry/react"
import { useCallback } from "react"

import { useToast } from "@utopia/ui"

export const useToastAndError = () => {
  const { toast } = useToast()

  return useCallback(
    (message: string, errorObj: Error | unknown) => {
      toast({ description: message })
      Sentry.captureMessage(JSON.stringify(errorObj))
      console.error(errorObj)
    },
    [toast],
  )
}
