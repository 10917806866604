import InlineSVG from "react-inlinesvg"

import {
  FormControl,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text,
} from "@utopia/ui"

export const stablecoins = {
  USDT: {
    logoSrc: "/usdt.svg",
  },
  USDC: {
    logoSrc: "/usdc.svg",
  },
}

interface StablecoinFromSelect
  extends Omit<React.ComponentPropsWithoutRef<typeof SelectTrigger>, "onChange"> {
  value: "USDT" | "USDC"
  onChange: (value: string) => void
  stablecoinFilter?: (stablecoin: keyof typeof stablecoins) => boolean
}

export const StablecoinFormSelect = ({
  value,
  onChange,
  ...props
}: StablecoinFromSelect) => {
  return (
    <Select value={value} onValueChange={onChange}>
      <FormControl>
        <SelectTrigger {...props}>
          <SelectValue>
            <div className="grid grid-flow-col items-center justify-start gap-1">
              <InlineSVG src={stablecoins[value].logoSrc} className="h-5 w-5 p-1" />
              <Text>{value}</Text>
            </div>
          </SelectValue>
        </SelectTrigger>
      </FormControl>
      <SelectContent>
        <SelectGroup>
          {Object.entries(stablecoins)
            .filter(([tokenName]) => {
              if (!props.stablecoinFilter) return true
              return props.stablecoinFilter(tokenName as keyof typeof stablecoins)
            })
            .map(([tokenName, stablecoin]) => (
              <SelectItem key={tokenName} value={tokenName}>
                <div className="grid grid-flow-col items-center justify-start gap-1">
                  <InlineSVG src={stablecoin.logoSrc} className="h-5 w-5 px-1 py-[4px]" />
                  <Text>{tokenName}</Text>
                </div>
              </SelectItem>
            ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
