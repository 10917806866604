import React, { Suspense, useMemo } from "react"
import { useFormContext } from "react-hook-form"
import InlineSVG from "react-inlinesvg"
import { z } from "zod"

import {
  calculateAmountWithTotalFeeInCents,
  formatCentsToDollars,
  formatDollarsToCents,
} from "@utopia/fees"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Heading,
  Input,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text,
} from "@utopia/ui"

import { Loading } from "#components/loading.js"
import { StablecoinFormSelect, stablecoins } from "#components/stablecoin-form-select.js"

import { CategoryFormSelect } from "../form/category-form-select.js"
import { BankAccountsFormSelect } from "./bank-accounts-form-select.js"
import { PaymentsTable } from "./payments-table.js"

const transferMethods = {
  ach: {
    name: "ACH transfer",
    description: "(1-3 day processing, $0.50 transaction fee)",
  },
  wire: {
    name: "Wire transfer",
    description: "(same-day processing before 5PM EDT, $20.00 transaction fee)",
  },
}

export const usdTransferFormSchema = z.object({
  amount: z
    .string()
    .regex(/^\d{1,}(\.\d{1,2})?$/, { message: "Amount has to be a valid dollar amount" })
    .refine((value) => parseFloat(value) >= 0.01 && parseFloat(value) < 10_000_000, {
      message: "Amount must be greater than 0.01 and less than 10,000,000",
    }),
  bankAccountId: z.string(),
  transferMethod: z.enum(["wire", "ach"]),
  stablecoin: z.enum(["USDC", "USDT"]),
  categoryId: z.string(),
  description: z.string(),
})

export type UsdTransferFormSchema = z.infer<typeof usdTransferFormSchema>

interface UsdTransferFormProps {
  isTransferEditable?: boolean
  handleSubmit: (data: UsdTransferFormSchema) => Promise<void>
  actionButtons: React.ReactNode
  headingText: string
}

// requires wrapping in a Form provider
export const UsdTransferForm = ({
  isTransferEditable = true,
  actionButtons,
  handleSubmit,
  headingText,
}: UsdTransferFormProps) => {
  const form = useFormContext<UsdTransferFormSchema>()
  const onSubmit = form.handleSubmit(handleSubmit)

  const currentAmount = form.watch("amount")
  const currentStablecoin = form.watch("stablecoin")
  const currentTransferMethod = form.watch("transferMethod")

  const paymentBreakdown = useMemo(() => {
    const currentAmountInCents = formatDollarsToCents(currentAmount)

    if (
      form.formState.errors.amount ||
      currentAmountInCents === "NaN" ||
      currentAmountInCents === "0" ||
      !currentTransferMethod
    ) {
      return {
        paymentTotalInCents: "0",
        paymentTotalInDollars: "0",
        transferMethodAmountInDollars: undefined,
        utopiaFeeInDollars: undefined,
        transferAmountInDollars: undefined,
      }
    }

    const transferAmountInDollars = formatCentsToDollars(currentAmountInCents)

    const {
      amountWithTotalFeeInCents,
      transferTypeFeeInCents: transferMethodAmountInCents,
      totalFeeInCents,
    } = calculateAmountWithTotalFeeInCents({
      type: "businessOffRamp",
      transferType: currentTransferMethod,
      amountInCents: currentAmountInCents,
      stablecoin: currentStablecoin.toLowerCase() as "usdc" | "usdt",
    })
    const paymentTotalInCents = amountWithTotalFeeInCents.toString()
    const paymentTotalInDollars = formatCentsToDollars(paymentTotalInCents)
    const transferMethodAmountInDollars = formatCentsToDollars(
      transferMethodAmountInCents,
    )

    const utopiaFeeInCents = totalFeeInCents.minus(transferMethodAmountInCents)
    const utopiaFeeInDollars = formatCentsToDollars(utopiaFeeInCents)

    return {
      paymentTotalInCents,
      paymentTotalInDollars,
      transferMethodAmountInDollars,
      utopiaFeeInDollars,
      transferAmountInDollars,
    }
  }, [
    currentAmount,
    currentTransferMethod,
    form.formState.errors.amount,
    currentStablecoin,
  ])

  return (
    <form className="w-full" onSubmit={onSubmit} noValidate>
      <div className="u-md:grid-cols-2 u-sm:px-10 grid gap-6 px-4">
        <div className="flex w-full justify-end">
          <div className="u-md:max-w-[593px] flex w-full flex-col gap-6 pt-10">
            <Heading size="lg">{headingText}</Heading>
            <fieldset className="grid gap-4" disabled={!isTransferEditable}>
              <Heading size="sm">Details</Heading>
              <FormField
                control={form.control}
                name="bankAccountId"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormItem>
                      <FormLabel>Transfer to</FormLabel>
                      <Suspense fallback={<Loading />}>
                        <BankAccountsFormSelect
                          value={value}
                          onChange={onChange}
                          disabled={!isTransferEditable}
                        />
                      </Suspense>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={form.control}
                name="amount"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Transfer amount</FormLabel>
                    <div className="grid auto-cols-[1fr_1fr] grid-flow-col">
                      <FormControl>
                        <Input
                          className="rounded-r-none"
                          {...field}
                          placeholder="Please enter an amount"
                        />
                      </FormControl>
                      <div className="grid grid-flow-col items-center justify-start gap-1 rounded-r-md border border-l-0 border-solid border-stone-200 bg-stone-50 px-4 py-3">
                        <InlineSVG src="/usd.svg" className="h-5 w-5 p-1" />
                        <Text size="md">USD</Text>
                      </div>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="transferMethod"
                render={({ field: { onChange, value } }) => (
                  <FormItem>
                    <FormLabel>Transfer method</FormLabel>
                    <Select value={value} onValueChange={(v) => onChange(v)}>
                      <FormControl>
                        <SelectTrigger disabled={!isTransferEditable}>
                          <SelectValue>
                            {value ? (
                              <Text>
                                {transferMethods[value].name}{" "}
                                <span className="text-stone-600">
                                  {transferMethods[value].description}
                                </span>
                              </Text>
                            ) : (
                              "Select a transfer method"
                            )}
                          </SelectValue>
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectGroup>
                          {Object.entries(transferMethods).map(([id, transferMethod]) => (
                            <SelectItem key={id} value={id}>
                              <Text>
                                {transferMethod.name}{" "}
                                <span className="text-stone-600">
                                  {transferMethod.description}
                                </span>
                              </Text>
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="stablecoin"
                render={({ field: { onChange, value } }) => (
                  <FormItem>
                    <FormLabel>Payment token</FormLabel>
                    <StablecoinFormSelect
                      value={value}
                      onChange={onChange}
                      disabled={!isTransferEditable}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </fieldset>

            <div className="flex flex-col gap-4">
              <Heading size="sm">Purpose</Heading>
              <FormField
                control={form.control}
                name="categoryId"
                render={({ field: { onChange, value } }) => (
                  <FormItem>
                    <FormLabel>Category</FormLabel>
                    <Suspense fallback={<Loading />}>
                      <CategoryFormSelect value={value} onChange={onChange} />
                    </Suspense>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <Input
                      className="rounded-t-none"
                      {...field}
                      placeholder="Describe this transfer"
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="u-md:block hidden">{actionButtons}</div>
          </div>
        </div>

        <div className="u-md:max-w-[593px] flex h-full w-full flex-col items-start gap-4">
          <Heading className="u-md:mt-[96px]" size="sm">
            Payment total
          </Heading>
          <PaymentsTable
            paymentBreakDown={paymentBreakdown}
            stablecoin={{
              name: currentStablecoin,
              logoSrc: stablecoins[currentStablecoin].logoSrc,
            }}
          />
        </div>
        <div className="u-md:hidden pb-10">{actionButtons}</div>
      </div>
    </form>
  )
}
