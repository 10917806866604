import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { Button, TableCell } from "@utopia/ui"

import { UsdTransferReviewDialog } from "#components/usd-transfers/usd-transfer-review-dialog.js"

import type { actionsCell_BankTransferFragment$key } from "./__generated__/actionsCell_BankTransferFragment.graphql"

// if loading a lot of data in the future, we can load in a separate query here instead of with the parent query
const BankTransferFragment = graphql`
  fragment actionsCell_BankTransferFragment on OfframpTransfer {
    ...usdTransferReviewDialog_OfframpTransferFragment
  }
`

interface ActionsCellProps {
  bankTransfer: actionsCell_BankTransferFragment$key
}

export function ActionsCell({ bankTransfer }: ActionsCellProps) {
  const bankTransferData = useFragment(BankTransferFragment, bankTransfer)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const onClickInfo = () => {
    setIsDialogOpen(true)
  }

  return (
    <TableCell className="align-top">
      <div className="flex gap-4">
        {isDialogOpen && (
          <UsdTransferReviewDialog
            bankTransfer={bankTransferData}
            onOpenChange={setIsDialogOpen}
            open={isDialogOpen}
          />
        )}
        <Button
          className="h-5 w-5 p-0"
          colorScheme="stone"
          variant="ghost"
          onClick={onClickInfo}
        >
          <InfoOutlinedIcon fontSize="small" className="text-stone-400" />
        </Button>
      </div>
    </TableCell>
  )
}
