interface FullscreenTopbarProps {
  children?: React.ReactNode
}

export function FullscreenTopbar(props: FullscreenTopbarProps) {
  return (
    <div className="sticky top-0 flex h-[64px] w-full items-center border-b bg-white p-4">
      {props.children}
    </div>
  )
}
