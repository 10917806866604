import { Route, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import { RelayEnvironment } from "#RelayEnvironment.js"
import { workspaceRoute } from "#pages/workspace/workspace-route.js"

import type { contacts_ContactsQuery } from "./__generated__/contacts_ContactsQuery.graphql.js"
import { ContactsQuery } from "./index.js"

export const contactsRoute = new Route({
  getParentRoute: () => workspaceRoute,
  path: "/contacts",
  component: lazyRouteComponent(() => import("./index.js"), "Contacts"),
  loader: ({ params: { slug } }) => {
    const contactsQueryReference = loadQuery<contacts_ContactsQuery>(
      RelayEnvironment,
      ContactsQuery,
      { input: { workspaceSlug: slug } },
    )
    return {
      contactsQueryReference,
    }
  },
})
