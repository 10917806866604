import { useMemo } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { Badge, TableCell } from "@utopia/ui"

import type { statusCell_BankTransferFragment$key } from "./__generated__/statusCell_BankTransferFragment.graphql"

const BankTransferFragment = graphql`
  fragment statusCell_BankTransferFragment on OfframpTransfer {
    status
  }
`

interface BankTransfersTableProps {
  bankTransfer: statusCell_BankTransferFragment$key
}

export function StatusCell({ bankTransfer }: BankTransfersTableProps) {
  const bankTransferData = useFragment(BankTransferFragment, bankTransfer)

  const colorVariants = {
    saffron: "text-saffron-500 bg-saffron-50 border-r-saffron-200",
    earth: "text-earth-500 bg-earth-50 border-r-earth-200",
    sunset: "text-sunset-500 bg-sunset-50 border-r-sunset-200",
  }

  const formattedStatus = useMemo(() => {
    switch (bankTransferData.status) {
      case "awaiting_funds":
      case "payment_submitted":
      case "funds_received":
        return { status: "Transfer in progress", colorScheme: "saffron" } as const
      case "payment_processed":
        return { status: "Transfer complete", colorScheme: "earth" } as const
      case "error":
        return { status: "Failed", colorScheme: "sunset" } as const
      case "canceled":
        return { status: "Canceled", colorScheme: "sunset" } as const
      default:
        return { status: "Unknown", colorScheme: "sunset" } as const
    }
  }, [bankTransferData.status])

  return (
    <TableCell className="align-top">
      <Badge className={colorVariants[formattedStatus.colorScheme]}>
        {formattedStatus.status}
      </Badge>
    </TableCell>
  )
}
