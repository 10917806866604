import InlineSVG from "react-inlinesvg"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { TableCell, Text } from "@utopia/ui"

import { formatAmount } from "#utils/tokens.js"

import type { transferAmountCell_BankTransferFragment$key } from "./__generated__/transferAmountCell_BankTransferFragment.graphql"

const BankTransferFragment = graphql`
  fragment transferAmountCell_BankTransferFragment on OfframpTransfer {
    stableCoinAmountInCents
    tokenAddress
    networkId
    fiatCurrency
  }
`

interface TransferAmountCellProps {
  bankTransfer: transferAmountCell_BankTransferFragment$key
}

export function TransferAmountCell({ bankTransfer }: TransferAmountCellProps) {
  const bankTransferData = useFragment(BankTransferFragment, bankTransfer)
  const formattedAmount = formatAmount(bankTransferData.stableCoinAmountInCents)
  const fiatCurrencySvg = "/usd.svg"

  return (
    <TableCell className="align-top">
      <div className="flex flex-wrap items-center justify-end">
        {formattedAmount}
        <InlineSVG src={fiatCurrencySvg} className="h-5 w-5 p-1" />
        <Text variant="lighter">{bankTransferData.fiatCurrency}</Text>
      </div>
    </TableCell>
  )
}
