import { Route, lazyRouteComponent } from "@tanstack/react-router"

import { workspaceRoute } from "#pages/workspace/workspace-route.js"

export const homeRoute = new Route({
  getParentRoute: () => workspaceRoute,
  path: "/home",
  component: lazyRouteComponent(() => import("./index.js"), "Home"),
  wrapInSuspense: false,
  loader: (...args) => {
    return import("./index.js").then((d) => d.loader(...args))
  },
})
