import { useWindowSize } from "@react-hookz/web"
import React, { useCallback, useEffect, useState } from "react"

interface NavbarContextType {
  isNavbarOpen: boolean
  toggleNavbar: (state: boolean) => void
}

const defaultState: NavbarContextType = {
  isNavbarOpen: true,
  toggleNavbar: () => {},
}

const NavbarContext = React.createContext<NavbarContextType>(defaultState)

export const useNavbar = () => React.useContext(NavbarContext)

interface NavbarProviderProps {
  children: React.ReactNode
}

export const NavbarProvider: React.FC<NavbarProviderProps> = ({ children }) => {
  const windowSize = useWindowSize()
  const [isNavbarOpen, setIsNavbarOpen] = useState<boolean>(windowSize.width >= 960)

  const toggleNavbar = useCallback((open: boolean) => {
    setIsNavbarOpen(open)
  }, [])

  // in case someone resizes their window with the navbar closed
  useEffect(() => {
    if (windowSize.width >= 960 && !isNavbarOpen) {
      setIsNavbarOpen(true)
    }
  }, [windowSize.width, isNavbarOpen])

  return (
    <NavbarContext.Provider value={{ isNavbarOpen, toggleNavbar }}>
      {children}
    </NavbarContext.Provider>
  )
}
