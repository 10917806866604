/**
 * @generated SignedSource<<b49aaab3fff3476e6c7fb4d9f9373da9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BridgeKycStatus = "approved" | "awaiting_ubo" | "incomplete" | "manual_review" | "not_started" | "pending" | "rejected" | "under_review";
export type KycStatusInput = {
  email: string;
  idempotencyKey: string;
  name: string;
};
export type useCreateKycStatus_CreateKycStatusMutation$variables = {
  input: KycStatusInput;
  workspaceId: string;
};
export type useCreateKycStatus_CreateKycStatusMutation$data = {
  readonly kycStatusCreate: {
    readonly createdAt: string;
    readonly email: string;
    readonly id: string;
    readonly kycUrl: string;
    readonly name: string;
    readonly status: BridgeKycStatus;
    readonly updatedAt: string;
    readonly workspaceId: string;
  };
};
export type useCreateKycStatus_CreateKycStatusMutation = {
  response: useCreateKycStatus_CreateKycStatusMutation$data;
  variables: useCreateKycStatus_CreateKycStatusMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workspaceId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      },
      {
        "kind": "Variable",
        "name": "workspaceId",
        "variableName": "workspaceId"
      }
    ],
    "concreteType": "KycStatus",
    "kind": "LinkedField",
    "name": "kycStatusCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "workspaceId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "kycUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateKycStatus_CreateKycStatusMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCreateKycStatus_CreateKycStatusMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "40d9ff6a27700d5bcfc504eec4427a7d",
    "id": null,
    "metadata": {},
    "name": "useCreateKycStatus_CreateKycStatusMutation",
    "operationKind": "mutation",
    "text": "mutation useCreateKycStatus_CreateKycStatusMutation(\n  $input: KycStatusInput!\n  $workspaceId: CUID2!\n) {\n  kycStatusCreate(input: $input, workspaceId: $workspaceId) {\n    id\n    workspaceId\n    name\n    email\n    status\n    kycUrl\n    createdAt\n    updatedAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "5d7fcf38eddf979bcd08f1f0a834ccf2";

export default node;
