export function ContactSupportLink() {
  const onKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      window.HubSpotConversations?.widget.open()
    }
  }

  return (
    <span
      className="text-earth-700 underline"
      onClick={() => window.HubSpotConversations?.widget.open()}
      onKeyDown={onKeyDown}
      role="button"
      tabIndex={0}
    >
      contact support
    </span>
  )
}
