import { Route, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import { RelayEnvironment } from "#RelayEnvironment.js"
import { workspaceRoute } from "#pages/workspace/workspace-route.js"

import type { bankTransfers_BankTransfersQuery } from "./__generated__/bankTransfers_BankTransfersQuery.graphql.js"
import type { bankTransfers_CategoriesQuery } from "./__generated__/bankTransfers_CategoriesQuery.graphql.js"
import { BankTransfersQuery, CategoriesQuery } from "./index.js"

export const bankTransfersRoute = new Route({
  getParentRoute: () => workspaceRoute,
  path: "/bank-transfers",
  wrapInSuspense: false,
  component: lazyRouteComponent(() => import("./index.js"), "BankTransfers"),
  loader: ({ params: { slug } }) => {
    const bankTransfersQueryReference = loadQuery<bankTransfers_BankTransfersQuery>(
      RelayEnvironment,
      BankTransfersQuery,
      {
        input: {
          workspaceSlug: slug,
        },
      },
    )

    const categoriesQueryReference = loadQuery<bankTransfers_CategoriesQuery>(
      RelayEnvironment,
      CategoriesQuery,
      {
        workspaceSlug: slug,
      },
    )

    return {
      bankTransfersQueryReference,
      categoriesQueryReference,
    }
  },
})
