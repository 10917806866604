import { Route, lazyRouteComponent } from "@tanstack/react-router"

import { workspaceRoute } from "#pages/workspace/workspace-route.js"

export const bankTransfersNewRoute = new Route({
  getParentRoute: () => workspaceRoute,
  path: "/bank-transfers/new",
  wrapInSuspense: false,
  component: lazyRouteComponent(() => import("./index.js"), "BankTransfersNew"),
})
