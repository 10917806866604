/**
 * @generated SignedSource<<b4377259861e667c4f580bb34ef9cdf7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type bankAccountDisplay_BankAccountFragment$data = {
  readonly accountOwnerName: string;
  readonly bankName: string;
  readonly contact: {
    readonly name: string;
  } | null | undefined;
  readonly lastFourDigits: string;
  readonly " $fragmentType": "bankAccountDisplay_BankAccountFragment";
};
export type bankAccountDisplay_BankAccountFragment$key = {
  readonly " $data"?: bankAccountDisplay_BankAccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"bankAccountDisplay_BankAccountFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "bankAccountDisplay_BankAccountFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bankName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastFourDigits",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountOwnerName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Contact",
      "kind": "LinkedField",
      "name": "contact",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BankAccount",
  "abstractKey": null
};

(node as any).hash = "d3dc7611c9e064c839c26718042cc977";

export default node;
