/**
 * @generated SignedSource<<db69b1ddd4d38ec39e5034e3c6d60a2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type categoryCell_BankTransferFragment$data = {
  readonly categoryId: string | null | undefined;
  readonly " $fragmentType": "categoryCell_BankTransferFragment";
};
export type categoryCell_BankTransferFragment$key = {
  readonly " $data"?: categoryCell_BankTransferFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"categoryCell_BankTransferFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "categoryCell_BankTransferFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "categoryId",
      "storageKey": null
    }
  ],
  "type": "OfframpTransfer",
  "abstractKey": null
};

(node as any).hash = "6e3520b7c3c031eada710905bf24de40";

export default node;
