/**
 * @generated SignedSource<<5facbc99c9c525227fdf72f8ca987efa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ContactEditInput = {
  bankAccounts?: ReadonlyArray<CreateBankAccountInput> | null | undefined;
  contactId: string;
  email: string;
  name: string;
  workspaceSlug: string;
};
export type CreateBankAccountInput = {
  accountNumber: string;
  accountOwnerName: string;
  address?: CreateBankAccountAddressInput | null | undefined;
  bankName: string;
  contactId?: string | null | undefined;
  idempotencyKey: string;
  routingNumber: string;
};
export type CreateBankAccountAddressInput = {
  city: string;
  country: string;
  postalCode: string;
  state: string;
  streetLine1: string;
  streetLine2?: string | null | undefined;
};
export type editContactDialog_EditContactMutation$variables = {
  input: ContactEditInput;
};
export type editContactDialog_EditContactMutation$data = {
  readonly contactEdit: {
    readonly contact: {
      readonly bankAccounts: ReadonlyArray<{
        readonly accountOwnerName: string;
        readonly bankName: string;
        readonly id: string;
        readonly lastFourDigits: string;
      }>;
      readonly email: string | null | undefined;
      readonly name: string;
    };
  };
};
export type editContactDialog_EditContactMutation = {
  response: editContactDialog_EditContactMutation$data;
  variables: editContactDialog_EditContactMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "BankAccount",
  "kind": "LinkedField",
  "name": "bankAccounts",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bankName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastFourDigits",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountOwnerName",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editContactDialog_EditContactMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ContactEditPayload",
        "kind": "LinkedField",
        "name": "contactEdit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Contact",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editContactDialog_EditContactMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ContactEditPayload",
        "kind": "LinkedField",
        "name": "contactEdit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Contact",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a6345a776b79fe6452d8ea49141690c2",
    "id": null,
    "metadata": {},
    "name": "editContactDialog_EditContactMutation",
    "operationKind": "mutation",
    "text": "mutation editContactDialog_EditContactMutation(\n  $input: ContactEditInput!\n) {\n  contactEdit(input: $input) {\n    contact {\n      name\n      email\n      bankAccounts {\n        id\n        bankName\n        lastFourDigits\n        accountOwnerName\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c0398db9c21301d1d0b4e3903615a2dd";

export default node;
