/**
 * @generated SignedSource<<1aef802b68aa6cadc15ce7c556309c15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type paymentTotalCell_BankTransferFragment$data = {
  readonly networkId: number;
  readonly paymentTotalInCents: string;
  readonly tokenAddress: string;
  readonly " $fragmentType": "paymentTotalCell_BankTransferFragment";
};
export type paymentTotalCell_BankTransferFragment$key = {
  readonly " $data"?: paymentTotalCell_BankTransferFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"paymentTotalCell_BankTransferFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "paymentTotalCell_BankTransferFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentTotalInCents",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tokenAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "networkId",
      "storageKey": null
    }
  ],
  "type": "OfframpTransfer",
  "abstractKey": null
};

(node as any).hash = "238c66e6d990169d49d38dca9afd032a";

export default node;
