import { useCallback, useState } from "react"
import { graphql } from "relay-runtime"

import { useToast } from "@utopia/ui"

import { usePromisifiedMutation } from "#hooks/use-promisified-mutation.js"
import { useToastAndError } from "#hooks/use-toast-and-error.js"
import { Mixpanel } from "#utils/mixpanel.js"

import type { BankAccount, ExistingBankAccount } from "../index.js"
import type { contactRow_ContactsFragment$data } from "./__generated__/contactRow_ContactsFragment.graphql"
import type { editContactDialog_EditContactMutation } from "./__generated__/editContactDialog_EditContactMutation.graphql"
import type { AddContactData } from "./add-contact-schema.js"
import { ContactDialog } from "./contact-dialog.js"

const EditContactMutation = graphql`
  mutation editContactDialog_EditContactMutation($input: ContactEditInput!) {
    contactEdit(input: $input) {
      contact {
        name
        email
        bankAccounts {
          id
          bankName
          lastFourDigits
          accountOwnerName
        }
      }
    }
  }
`

interface ContactDialogProps {
  workspaceSlug: string
  isOpen: boolean
  contact: contactRow_ContactsFragment$data
  bankAccounts?: ExistingBankAccount[]
  onOpenChange: (open: boolean) => void
  refetchContacts: VoidFunction
}

export const EditContactDialog = ({
  workspaceSlug,
  isOpen,
  onOpenChange,
  contact,
  bankAccounts,
  refetchContacts,
}: ContactDialogProps) => {
  const { toast } = useToast()
  const toastAndError = useToastAndError()
  const [stagedContact, setStagedContact] = useState<AddContactData | null>({
    id: contact.id,
    name: contact.name,
    email: contact.email ? contact.email.toLowerCase() : "",
    bankAccounts: [],
  })
  const [stagedBankAccounts, setStagedBankAccounts] = useState<BankAccount[] | null>([])

  const [editContact] =
    usePromisifiedMutation<editContactDialog_EditContactMutation>(EditContactMutation)

  const onSubmit = useCallback(
    async (data: AddContactData): Promise<void> => {
      try {
        const decodedId = atob(contact.id)
        const decodedContactId = decodedId.split(":")[1]
        const input = {
          contactId: decodedContactId,
          name: data.name,
          email: data.email ? data.email.toLowerCase() : "",
          workspaceSlug,
          bankAccounts: stagedBankAccounts,
        }

        await editContact({
          variables: {
            input,
          },
        })
        refetchContacts()
        toast({ title: `${data.name} and associated bank accounts have been added.` })
        Mixpanel.track("nexus:contact_Added")
      } catch (error) {
        toastAndError(
          "Error creating contact. You may be using an already existing email ID or adding an existing Bank Account.",
          error,
        )
        if (error instanceof Error) {
          Mixpanel.track("nexus:contact_failed", {
            message: error.message,
          })
        } else {
          Mixpanel.track("nexus:contact_failed")
        }
      }
    },
    [
      stagedBankAccounts,
      editContact,
      toast,
      toastAndError,
      workspaceSlug,
      contact.id,
      refetchContacts,
    ],
  )

  return (
    <ContactDialog
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      onSubmit={onSubmit}
      bankAccounts={bankAccounts}
      stagedBankAccounts={stagedBankAccounts}
      stagedContact={stagedContact}
      setStagedContact={setStagedContact}
      setStagedBankAccounts={setStagedBankAccounts}
    />
  )
}
