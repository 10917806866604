/**
 * @generated SignedSource<<1d9a38c5deed54c05720aed1d616c57f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contactRow_ContactsFragment$data = {
  readonly bankAccounts: ReadonlyArray<{
    readonly accountOwnerName: string;
    readonly bankName: string;
    readonly id: string;
    readonly lastFourDigits: string;
  }>;
  readonly email: string | null | undefined;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "contactRow_ContactsFragment";
};
export type contactRow_ContactsFragment$key = {
  readonly " $data"?: contactRow_ContactsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"contactRow_ContactsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contactRow_ContactsFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccount",
      "kind": "LinkedField",
      "name": "bankAccounts",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bankName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastFourDigits",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountOwnerName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Contact",
  "abstractKey": null
};
})();

(node as any).hash = "214dcd3b8b0bf153e786ce8364a130c9";

export default node;
