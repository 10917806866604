import SearchIcon from "@mui/icons-material/Search"
import { useLoader, useParams, useRouter, useSearch } from "@tanstack/react-router"
import { useCallback, useEffect, useState } from "react"
import { graphql, loadQuery, usePreloadedQuery } from "react-relay"
import type { FragmentRefs } from "relay-runtime"

import { Button, Input, useToast } from "@utopia/ui"

import { RelayEnvironment } from "#RelayEnvironment.js"
import { useAuthenticatedUserInfo } from "#store/user-info.js"

import type { contacts_ContactsQuery } from "./__generated__/contacts_ContactsQuery.graphql.js"
import { AddContactDialog } from "./components/add-contact-dialog.js"
import { ContactsTable } from "./components/contacts-table.js"
import { EmptyContactsPage } from "./components/empty-state.js"

export interface Address {
  city: string
  country: string
  postalCode: string
  state: string
  streetLine1: string
  streetLine2?: string
}

export interface BankAccount {
  idempotencyKey: string
  address?: Address
  accountNumber: string
  routingNumber: string
  bankName: string
  accountOwnerName: string
  accountName: string
}

export interface ExistingBankAccount {
  id: string
  bankName: string
  lastFourDigits: string
  accountOwnerName: string
}

export interface BankAccountData {
  idempotencyKey: string
  accountOwnerName: string
  accountNumber: string
  accountName: string
  routingNumber: string
  fullAddress: string
  addressLine1: string
  addressLine2: string
  country: string
  city: string
  state: string
  zip: string
  bankName: string
}

export const ContactsQuery = graphql`
  query contacts_ContactsQuery($input: ContactSearchInput!) {
    contacts(input: $input) {
      edges {
        node {
          ...contactsTable_ContactsFragment
        }
      }
    }
  }
`

const fromRoute = "/authenticated-route/$slug/contacts"

export const Contacts = () => {
  const [searchQuery, setSearchQuery] = useState("")
  const { contactsQueryReference } = useLoader({
    from: fromRoute,
  })

  const { toast } = useToast()

  const {
    me: {
      user: { workspaces },
    },
  } = useAuthenticatedUserInfo()

  const { slug } = useParams({
    from: fromRoute,
  })

  const { addContactForTransfer } = useSearch({ from: fromRoute }) as {
    addContactForTransfer?: boolean
  }

  const contactsData = usePreloadedQuery<contacts_ContactsQuery>(
    ContactsQuery,
    contactsQueryReference,
  )

  const [addContactsOpen, setAddContactsOpen] = useState(false)

  const openContactsModal = useCallback(() => {
    if (!workspaces[0].customerId) {
      toast({
        description:
          "Workspace not verified. Please verify your workspace before adding contacts",
      })
    } else {
      setAddContactsOpen(true)
    }
  }, [toast, workspaces])

  useEffect(() => {
    if (addContactForTransfer) {
      openContactsModal()
    }
  }, [addContactForTransfer, openContactsModal])

  // navigate back to add transfers
  const router = useRouter()

  const onOpenChange = (isOpen: boolean) => {
    setAddContactsOpen(isOpen)
    if (!isOpen && addContactForTransfer) {
      router.history.back()
    }
  }

  const refetchContacts = useCallback(() => {
    const { variables } = contactsQueryReference
    loadQuery(RelayEnvironment, ContactsQuery, variables, {
      fetchPolicy: "network-only",
    })
  }, [contactsQueryReference])

  return (
    <div className="flex h-full w-full flex-col">
      <div className="mx-6 mb-2 mt-6 flex flex-col space-x-2 space-y-2 sm:flex-row sm:space-y-0">
        <div className="h-13 flex flex-grow items-center gap-2 rounded-lg border px-4">
          <SearchIcon className="text-stone-500" fontSize="small" />
          <Input
            className="h-5 border-0 px-0 py-4"
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search by name, email, or bank name"
          />
        </div>

        <Button onClick={openContactsModal}>Add contact</Button>
      </div>
      {addContactsOpen && (
        <AddContactDialog
          workspaceSlug={slug}
          isOpen={addContactsOpen}
          onOpenChange={onOpenChange}
          refetchContacts={refetchContacts}
        />
      )}
      {contactsData.contacts.edges.length > 0 ? (
        <div className="m-6">
          <ContactsTable
            refetchContacts={refetchContacts}
            workspaceSlug={slug}
            contacts={
              contactsData.contacts.edges
                ? contactsData.contacts.edges
                    .filter(
                      (
                        edge,
                      ): edge is {
                        node: {
                          " $fragmentSpreads": FragmentRefs<"contactsTable_ContactsFragment">
                        }
                      } => edge != null && edge.node != null,
                    )
                    .map((edge) => edge.node)
                : []
            }
            searchQuery={searchQuery}
          />
        </div>
      ) : (
        <EmptyContactsPage onClick={openContactsModal} />
      )}
    </div>
  )
}
