import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { Badge, TableCell } from "@utopia/ui"

import type { categoryCell_CategoryFragment$key } from "./__generated__/categoryCell_CategoryFragment.graphql"

const CategoriesFragment = graphql`
  fragment categoryCell_CategoryFragment on Category @relay(plural: true) {
    id
    name
  }
`

interface CategoryCellProps {
  categories: categoryCell_CategoryFragment$key
  categoryId: string | null | undefined
}

export function CategoryCell({ categories, categoryId }: CategoryCellProps) {
  const categoriesData = useFragment(CategoriesFragment, categories)
  const categoryName = categoriesData.find((c) => c.id === categoryId)?.name

  return (
    <TableCell className="align-top">
      {categoryName ? <Badge>{categoryName}</Badge> : null}
    </TableCell>
  )
}
