import { DateFormatter, getLocalTimeZone, parseDate } from "@internationalized/date"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { TableCell } from "@utopia/ui"

import type { sentAtCell_BankTransferFragment$key } from "./__generated__/sentAtCell_BankTransferFragment.graphql"

const BankTransferFragment = graphql`
  fragment sentAtCell_BankTransferFragment on OfframpTransfer {
    sentAt
  }
`

interface BankTransfersTableProps {
  bankTransfer: sentAtCell_BankTransferFragment$key
}

export function SentAtCell({ bankTransfer }: BankTransfersTableProps) {
  const bankTransferData = useFragment(BankTransferFragment, bankTransfer)
  const parsedDate = parseDate(bankTransferData.sentAt)
  const formattedDate = new DateFormatter("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  }).format(parsedDate.toDate(getLocalTimeZone()))

  return <TableCell className="align-top">{formattedDate}</TableCell>
}
