/**
 * @generated SignedSource<<95abb9478d35f3ce2e55c131d7ec80f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OfframpTransferSearchInput = {
  searchTerm?: string | null | undefined;
  workspaceSlug: string;
};
export type bankTransfers_BankTransfersQuery$variables = {
  input: OfframpTransferSearchInput;
};
export type bankTransfers_BankTransfersQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"bankTransfersTable_BankTransferFragment">;
};
export type bankTransfers_BankTransfersQuery = {
  response: bankTransfers_BankTransfersQuery$data;
  variables: bankTransfers_BankTransfersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bankTransfers_BankTransfersQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "bankTransfersTable_BankTransferFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bankTransfers_BankTransfersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "QueryOfframpTransfersConnection",
        "kind": "LinkedField",
        "name": "offrampTransfers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "QueryOfframpTransfersConnectionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OfframpTransfer",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BankAccount",
                    "kind": "LinkedField",
                    "name": "bankAccount",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "accountOwnerName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "bankName",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "stableCoinAmountInCents",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tokenAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "networkId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fiatCurrency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "paymentTotalInCents",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "categoryId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sentAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fiatPaymentRail",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bankAccountId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "input"
        ],
        "handle": "connection",
        "key": "bankTransfersTable_BankTransfersFragment_offrampTransfers",
        "kind": "LinkedHandle",
        "name": "offrampTransfers"
      }
    ]
  },
  "params": {
    "cacheID": "aef649cf38932525ac2718bb8dd1cb68",
    "id": null,
    "metadata": {},
    "name": "bankTransfers_BankTransfersQuery",
    "operationKind": "query",
    "text": "query bankTransfers_BankTransfersQuery(\n  $input: OfframpTransferSearchInput!\n) {\n  ...bankTransfersTable_BankTransferFragment_2VV6jB\n}\n\nfragment actionsCell_BankTransferFragment on OfframpTransfer {\n  ...usdTransferReviewDialog_OfframpTransferFragment\n}\n\nfragment bankTransfersTable_BankTransferFragment_2VV6jB on Query {\n  offrampTransfers(input: $input) {\n    edges {\n      node {\n        id\n        ...recipientCell_BankTransferFragment\n        ...transferAmountCell_BankTransferFragment\n        ...paymentTotalCell_BankTransferFragment\n        ...categoryCell_BankTransferFragment\n        ...descriptionCell_BankTransferFragment\n        ...sentAtCell_BankTransferFragment\n        ...statusCell_BankTransferFragment\n        ...actionsCell_BankTransferFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n      hasPreviousPage\n      startCursor\n    }\n  }\n}\n\nfragment categoryCell_BankTransferFragment on OfframpTransfer {\n  categoryId\n}\n\nfragment descriptionCell_BankTransferFragment on OfframpTransfer {\n  description\n}\n\nfragment paymentTotalCell_BankTransferFragment on OfframpTransfer {\n  paymentTotalInCents\n  tokenAddress\n  networkId\n}\n\nfragment recipientCell_BankTransferFragment on OfframpTransfer {\n  bankAccount {\n    accountOwnerName\n    bankName\n    id\n  }\n}\n\nfragment sentAtCell_BankTransferFragment on OfframpTransfer {\n  sentAt\n}\n\nfragment statusCell_BankTransferFragment on OfframpTransfer {\n  status\n}\n\nfragment transferAmountCell_BankTransferFragment on OfframpTransfer {\n  stableCoinAmountInCents\n  tokenAddress\n  networkId\n  fiatCurrency\n}\n\nfragment usdTransferReviewDialog_OfframpTransferFragment on OfframpTransfer {\n  id\n  stableCoinAmountInCents\n  fiatPaymentRail\n  fiatCurrency\n  bankAccountId\n  categoryId\n  description\n  tokenAddress\n  networkId\n}\n"
  }
};
})();

(node as any).hash = "717a7f7923863c1abe54ca657113d0e9";

export default node;
