import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { Avatar, AvatarFallback, Text } from "@utopia/ui"

import { getInitials } from "#utils/get-initials.js"

import type { bankAccountDisplay_BankAccountFragment$key } from "./__generated__/bankAccountDisplay_BankAccountFragment.graphql"

const BankAccountFragment = graphql`
  fragment bankAccountDisplay_BankAccountFragment on BankAccount {
    bankName
    lastFourDigits
    accountOwnerName
    contact {
      name
    }
  }
`

export function BankAccountWithRecipientDisplay({
  bankAccount,
}: {
  bankAccount: bankAccountDisplay_BankAccountFragment$key
}) {
  const bankAccountData = useFragment(BankAccountFragment, bankAccount)
  const contactName = bankAccountData.contact?.name
  return (
    <div className="flex w-full gap-2">
      <Avatar>
        <AvatarFallback>{getInitials(contactName || "")}</AvatarFallback>
      </Avatar>
      <div className="grid">
        <Text className="text-start">{contactName}</Text>
        <div className="flex flex-row">
          <Text size="xs" variant="lighter">
            {`${bankAccountData.bankName} • ${bankAccountData.lastFourDigits}`}
          </Text>
        </div>
      </div>
    </div>
  )
}
