import { zodResolver } from "@hookform/resolvers/zod"
import CloseIcon from "@mui/icons-material/Close"
import { useParams } from "@tanstack/react-router"
import { useMemo } from "react"
import { useForm } from "react-hook-form"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { chainIdToUsdcAddress, chainIdToUsdtAddress } from "@utopia/evm"
import { formatCentsToDollars } from "@utopia/fees"
import { Button, Dialog, DialogContent, Form, useToast } from "@utopia/ui"

import { FullscreenTopbar } from "#components/fullscreen-topbar.js"
import { usePromisifiedMutation } from "#hooks/use-promisified-mutation.js"
import { useToastAndError } from "#hooks/use-toast-and-error.js"
import { Mixpanel, NexusEvent } from "#utils/mixpanel.js"

import type { usdTransferReviewDialog_OfframpTransferEditMetadataMutation } from "./__generated__/usdTransferReviewDialog_OfframpTransferEditMetadataMutation.graphql.js"
import type { usdTransferReviewDialog_OfframpTransferFragment$key } from "./__generated__/usdTransferReviewDialog_OfframpTransferFragment.graphql.js"
import type { UsdTransferFormSchema } from "./usd-transfer-form.js"
import { UsdTransferForm, usdTransferFormSchema } from "./usd-transfer-form.js"

const EditOfframpTransferMetadataMutation = graphql`
  mutation usdTransferReviewDialog_OfframpTransferEditMetadataMutation(
    $input: OfframpTransferEditMetadataInput!
  ) {
    offrampTransferEditMetadata(input: $input) {
      offrampTransfer {
        id
        categoryId
        description
      }
    }
  }
`

const OfframpTransferFragment = graphql`
  fragment usdTransferReviewDialog_OfframpTransferFragment on OfframpTransfer {
    id
    stableCoinAmountInCents
    fiatPaymentRail
    fiatCurrency
    bankAccountId
    categoryId
    description
    tokenAddress
    networkId
  }
`

interface UsdTransferReviewDialogProps {
  bankTransfer: usdTransferReviewDialog_OfframpTransferFragment$key
  open: boolean
  onOpenChange: (open: boolean) => void
}

export const UsdTransferReviewDialog = ({
  bankTransfer,
  open,
  onOpenChange,
}: UsdTransferReviewDialogProps) => {
  const { slug: workspaceSlug } = useParams({
    from: "/authenticated-route/$slug",
  })
  const [editOfframpTransferMetadata] =
    usePromisifiedMutation<usdTransferReviewDialog_OfframpTransferEditMetadataMutation>(
      EditOfframpTransferMetadataMutation,
    )
  const toastAndError = useToastAndError()
  const { toast } = useToast()

  const bankTransferData = useFragment(OfframpTransferFragment, bankTransfer)

  const stablecoin = (() => {
    if (
      chainIdToUsdcAddress[bankTransferData.networkId] === bankTransferData.tokenAddress
    ) {
      return "USDC"
    }
    if (
      chainIdToUsdtAddress[bankTransferData.networkId] === bankTransferData.tokenAddress
    ) {
      return "USDT"
    }
    throw new Error(
      `Invalid token address and network id combination for ${bankTransferData.id}`,
    )
  })()

  const form = useForm<UsdTransferFormSchema>({
    mode: "onTouched",
    resolver: zodResolver(usdTransferFormSchema),
    defaultValues: {
      stablecoin,
      amount: formatCentsToDollars(bankTransferData.stableCoinAmountInCents),
      description: bankTransferData.description ?? undefined,
      categoryId: bankTransferData.categoryId ?? undefined,
      bankAccountId: bankTransferData.bankAccountId ?? undefined,
      transferMethod: bankTransferData.fiatPaymentRail ?? undefined,
    },
  })

  const isValidForm = Object.keys(form.formState.errors).length === 0
  const actionButtons = useMemo(() => {
    return (
      <div className="flex flex-col gap-4">
        <Button
          isLoading={form.formState.isSubmitting}
          type="submit"
          disabled={!isValidForm || !form.formState.isDirty || !form.formState.isValid}
        >
          Update
        </Button>
        <Button variant="outline" type="button" onClick={() => onOpenChange(false)}>
          Cancel
        </Button>
      </div>
    )
  }, [
    form.formState.isDirty,
    form.formState.isSubmitting,
    form.formState.isValid,
    isValidForm,
    onOpenChange,
  ])

  const editTransfer = async (data: UsdTransferFormSchema) => {
    try {
      await editOfframpTransferMetadata({
        variables: {
          input: {
            id: bankTransferData.id,
            workspaceSlug,
            description: data.description,
            categoryId: data.categoryId,
          },
        },
      })

      toast({ description: "Successfully updated" })
      onOpenChange(false)
    } catch (error) {
      Mixpanel.track(NexusEvent.bankTransfers_failed, {
        reason: "Failed to edit USD transfer",
        error,
      })

      toastAndError("Failed to edit USD transfer", error)
    }
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent size="full" className="p-0">
        <div>
          <FullscreenTopbar>
            <div className="flex w-full items-center justify-end">
              <Button
                className="hover:bg-inherit"
                colorScheme="stone"
                variant="ghost"
                onClick={() => onOpenChange(false)}
              >
                <CloseIcon />
              </Button>
            </div>
          </FullscreenTopbar>
          <Form {...form}>
            <UsdTransferForm
              headingText="Review USD transfer."
              handleSubmit={editTransfer}
              actionButtons={actionButtons}
              isTransferEditable={false}
            />
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  )
}
