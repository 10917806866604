import { z } from "zod"

export const configSchema = z.object({
  MODE: z.enum(["development", "test", "production"]),
  VITE_ENVIRONMENT: z.enum(["local", "staging", "test", "production"]),
  VITE_APP_TYPE: z.enum(["demo", "live"]).optional(),

  VITE_SENTRY_AUTH_TOKEN: z.string().optional(),
  VITE_FC_GIT_COMMIT_SHA: z.string().optional(),

  FC_GIT_COMMIT_SHA: z.string().optional(),

  SENTRY_AUTH_TOKEN: z.string().optional(),
  PORT: z.coerce.number().optional(),
  API_URL: z.string().optional(),

  WALLET_CONNECT_PROJECT_ID: z.string().optional(),
  VITE_GOOGLE_PLACES_API_KEY: z.string().optional(),

  VITE_PRIVY_APP_ID: z.string().optional(),
  VITE_ZERODEV_PROJECT_ID: z.string().optional(),
  VITE_MIXPANEL_TOKEN: z.string(),

  VITE_VERCEL_ENV: z.enum(["production", "preview", "development"]).optional(),
})

export const validateConfig = (env: Record<string, string | undefined>) => {
  try {
    return configSchema.parse(env)
  } catch (error) {
    const printError = (err: z.ZodError) => {
      console.error(
        "Environment variables validation failed. App is misconfigured. Please see more info below:\n",
      )
      err.errors.forEach((anError) => {
        console.error(`${anError.path.join(".")}: ${anError.message}`)
      })
    }

    if (error instanceof z.ZodError) {
      printError(error)
      // eslint-disable-next-line no-throw-literal
      throw "Wrong configuration, see info above this message"
    } else {
      throw error
    }
  }
}
