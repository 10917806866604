/* eslint-disable no-useless-concat */
import { getAccessToken } from "@privy-io/react-auth"
import { Environment, Network, RecordSource, Store } from "relay-runtime"
import type { FetchFunction, GraphQLResponse } from "relay-runtime"

const HTTP_ENDPOINT = window.origin
  ? `${window.origin}/api/graphql`
  : "http://localhost:3000" + "/api/graphql"

const fetchFn: FetchFunction = async (request, variables, cacheConfig, uploadables) => {
  let init: RequestInit

  if (uploadables) {
    if (!window.FormData) {
      throw new Error("Uploading files without `FormData` not supported.")
    }
    const formData = new FormData()
    formData.append(
      "operations",
      JSON.stringify({
        query: request.text,
        variables,
      }),
    )

    const uploadableMap: {
      [key: string]: string[]
    } = {}

    Object.keys(uploadables).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        uploadableMap[key] = [`variables.${key}`]
      }
    })

    formData.append("map", JSON.stringify(uploadableMap))

    Object.keys(uploadables).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        formData.append(key, uploadables[key])
      }
    })
    init = {
      method: "POST",
      body: formData,
    }
  } else {
    init = {
      method: "POST",
      headers: {
        Accept:
          "application/graphql-response+json; charset=utf-8, application/json; charset=utf-8",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: request.text, // <-- The GraphQL document composed by Relay
        variables,
      }),
    }
  }

  const resp = await fetch(HTTP_ENDPOINT, init)

  return (await resp.json()) as GraphQLResponse
}

const fetchFnWithAuthRetry: FetchFunction = async (
  request,
  variables,
  cacheConfig,
  uploadables,
) => {
  const response = (await fetchFn(
    request,
    variables,
    cacheConfig,
    uploadables,
  )) as GraphQLResponse

  // after the session cookie expires, requests will be missing the access token, so we need to manually refresh it
  if (
    "errors" in response &&
    !!response.errors?.length &&
    response.errors[0].message === "Not authorized"
  ) {
    await getAccessToken()

    return fetchFn(request, variables, cacheConfig, uploadables) as GraphQLResponse
  }

  return response
}

function createRelayEnvironment() {
  return new Environment({
    network: Network.create(fetchFnWithAuthRetry),
    store: new Store(new RecordSource()),
  })
}

export const RelayEnvironment = createRelayEnvironment()
