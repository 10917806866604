import { useCallback, useState } from "react"
import { graphql } from "relay-runtime"

import { useToast } from "@utopia/ui"

import { usePromisifiedMutation } from "#hooks/use-promisified-mutation.js"
import { useToastAndError } from "#hooks/use-toast-and-error.js"
import type { BankAccount } from "#pages/contacts/index.js"
import { Mixpanel } from "#utils/mixpanel.js"

import type { addContactDialog_AddContactMutation } from "./__generated__/addContactDialog_AddContactMutation.graphql"
import type { AddContactData } from "./add-contact-schema"
import { ContactDialog } from "./contact-dialog.js"

const AddContactMutation = graphql`
  mutation addContactDialog_AddContactMutation($input: ContactCreateInput!) {
    contactCreate(input: $input) {
      contact {
        id
        name
        email
      }
    }
  }
`

interface AddContactDialogProps {
  workspaceSlug: string
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  refetchContacts: VoidFunction
}

export const AddContactDialog = ({
  workspaceSlug,
  isOpen,
  onOpenChange,
  refetchContacts,
}: AddContactDialogProps) => {
  const { toast } = useToast()
  const toastAndError = useToastAndError()
  const [stagedContact, setStagedContact] = useState<AddContactData | null>({
    name: "",
    email: "",
    bankAccounts: [],
  })
  const [stagedBankAccounts, setStagedBankAccounts] = useState<BankAccount[] | null>([])

  const [addContact] =
    usePromisifiedMutation<addContactDialog_AddContactMutation>(AddContactMutation)

  const onSubmit = useCallback(
    async (data: AddContactData): Promise<void> => {
      try {
        const input = {
          name: data.name,
          email: data.email ? data.email.toLowerCase() : "",
          workspaceSlug,
          bankAccounts: stagedBankAccounts,
        }

        await addContact({
          variables: {
            input,
          },
        })
        toast({ title: `${data.name} and associated bank accounts have been added.` })
        Mixpanel.track("nexus:contact_Added")
        refetchContacts()
      } catch (error) {
        toastAndError(
          "Error creating contact. You may be using an already existing email or Bank Account.",
          error,
        )
        if (error instanceof Error) {
          Mixpanel.track("nexus:contact_failed", {
            message: error.message,
          })
        } else {
          Mixpanel.track("nexus:contact_failed")
        }
      }
    },
    [
      addContact,
      toast,
      toastAndError,
      workspaceSlug,
      stagedBankAccounts,
      refetchContacts,
    ],
  )

  return (
    <ContactDialog
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      onSubmit={onSubmit}
      stagedContact={stagedContact}
      stagedBankAccounts={stagedBankAccounts}
      setStagedContact={setStagedContact}
      setStagedBankAccounts={setStagedBankAccounts}
    />
  )
}
