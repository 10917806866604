import { Route, lazyRouteComponent } from "@tanstack/react-router"

import { workspaceRoute } from "#pages/workspace/workspace-route.js"

export const depositsRoute = new Route({
  getParentRoute: () => workspaceRoute,
  path: "/deposits",
  component: lazyRouteComponent(() => import("./index.js"), "Deposits"),
  loader: (...args) => {
    return import("./index.js").then((d) => d.loader(...args))
  },
})
