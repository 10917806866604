import { useLoader } from "@tanstack/react-router"
import { usePreloadedQuery } from "react-relay"
import { graphql } from "relay-runtime"

import { Topbar } from "#components/topbar-v2.js"

import type { bankTransfers_BankTransfersQuery } from "./__generated__/bankTransfers_BankTransfersQuery.graphql"
import type { bankTransfers_CategoriesQuery } from "./__generated__/bankTransfers_CategoriesQuery.graphql"
import { BankTransfersTable } from "./components/bank-transfers-table/index.js"

export const BankTransfersQuery = graphql`
  query bankTransfers_BankTransfersQuery($input: OfframpTransferSearchInput!) {
    ...bankTransfersTable_BankTransferFragment @arguments(input: $input)
  }
`

export const CategoriesQuery = graphql`
  query bankTransfers_CategoriesQuery($workspaceSlug: String!) {
    categories(workspaceSlug: $workspaceSlug) {
      ...bankTransfersTable_CategoryFragment
    }
  }
`

const fromRoute = "/authenticated-route/$slug/bank-transfers"

export const BankTransfers = () => {
  const { bankTransfersQueryReference, categoriesQueryReference } = useLoader({
    from: fromRoute,
  })

  const bankTransfersData = usePreloadedQuery<bankTransfers_BankTransfersQuery>(
    BankTransfersQuery,
    bankTransfersQueryReference,
  )

  const categoriesData = usePreloadedQuery<bankTransfers_CategoriesQuery>(
    CategoriesQuery,
    categoriesQueryReference,
  )

  return (
    <div className="flex h-full w-full flex-col">
      <Topbar title="USD Transfers" />
      <div className="u-sm:p-10 u-md:max-h-[100vh] h-full max-h-[calc(100vh-64px)] p-4">
        <BankTransfersTable
          bankTransfers={bankTransfersData}
          categories={categoriesData.categories}
        />
      </div>
    </div>
  )
}
