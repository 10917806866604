import { useLoader } from "@tanstack/react-router"
import { useCallback } from "react"
import { loadQuery, usePreloadedQuery } from "react-relay"
import { graphql } from "relay-runtime"

import { RelayEnvironment } from "#RelayEnvironment"
import { Topbar } from "#components/topbar.js"
import { useNavbar } from "#providers/navbar.js"

import type { verification_WorkspaceQuery } from "./__generated__/verification_WorkspaceQuery.graphql"
import { ApprovedVerificationForm } from "./components/approved-verification-form.js"
import { InReviewVerificationForm } from "./components/in-review-verification-form.js"
import { PersonaVerification } from "./components/persona-verification.js"
import { RejectedVerificationForm } from "./components/rejected-verification-form.js"
import { TermsOfService } from "./components/terms-of-service.js"

export const WorkspaceQuery = graphql`
  query verification_WorkspaceQuery($workspaceSlug: String!) {
    workspace(workspaceSlug: $workspaceSlug) {
      id
      kycStatus {
        status
      }
      ...termsOfService_WorkspaceFragment
      ...inReviewVerificationForm_WorkspaceFragment
      ...rejectedVerificationForm_WorkspaceFragment
      ...approvedVerificationForm_WorkspaceFragment
      ...personaVerification_WorkspaceFragment
    }
  }
`

export function Verification() {
  const { isNavbarOpen } = useNavbar()

  return (
    <div>
      {!isNavbarOpen && <Topbar />}
      <div className="m-10 flex max-w-[572px]">
        <VerificationContent />
      </div>
    </div>
  )
}

function VerificationContent() {
  const queryReference = useLoader({
    from: "/authenticated-route/$slug/verification",
  })

  const data = usePreloadedQuery<verification_WorkspaceQuery>(
    WorkspaceQuery,
    queryReference,
  )

  const kycStatus = data.workspace.kycStatus

  const refreshKycStatus = useCallback(() => {
    const { variables } = queryReference
    loadQuery(RelayEnvironment, WorkspaceQuery, variables, {
      fetchPolicy: "network-only",
    })
  }, [queryReference])

  if (!kycStatus) {
    return (
      <TermsOfService workspace={data.workspace} refreshKycStatus={refreshKycStatus} />
    )
  }

  if (
    kycStatus.status === "pending" ||
    kycStatus.status === "incomplete" ||
    kycStatus.status === "not_started" ||
    kycStatus.status === "awaiting_ubo"
  ) {
    return <PersonaVerification workspace={data.workspace} />
  }

  if (kycStatus.status === "manual_review" || kycStatus.status === "under_review") {
    return <InReviewVerificationForm workspace={data.workspace} />
  }

  if (kycStatus.status === "rejected") {
    return <RejectedVerificationForm workspace={data.workspace} />
  }

  if (kycStatus.status === "approved") {
    return <ApprovedVerificationForm workspace={data.workspace} />
  }
}
