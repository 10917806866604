/**
 * @generated SignedSource<<0d2faaa9b9f55b2cc3e48f85130b46d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ContactCreateInput = {
  bankAccounts?: ReadonlyArray<CreateBankAccountInput> | null | undefined;
  email: string;
  name: string;
  workspaceSlug: string;
};
export type CreateBankAccountInput = {
  accountNumber: string;
  accountOwnerName: string;
  address?: CreateBankAccountAddressInput | null | undefined;
  bankName: string;
  contactId?: string | null | undefined;
  idempotencyKey: string;
  routingNumber: string;
};
export type CreateBankAccountAddressInput = {
  city: string;
  country: string;
  postalCode: string;
  state: string;
  streetLine1: string;
  streetLine2?: string | null | undefined;
};
export type addContactDialog_AddContactMutation$variables = {
  input: ContactCreateInput;
};
export type addContactDialog_AddContactMutation$data = {
  readonly contactCreate: {
    readonly contact: {
      readonly email: string | null | undefined;
      readonly id: string;
      readonly name: string;
    };
  };
};
export type addContactDialog_AddContactMutation = {
  response: addContactDialog_AddContactMutation$data;
  variables: addContactDialog_AddContactMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ContactCreatePayload",
    "kind": "LinkedField",
    "name": "contactCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Contact",
        "kind": "LinkedField",
        "name": "contact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addContactDialog_AddContactMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addContactDialog_AddContactMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e922947b27a8f851d4ea582baf74cd1d",
    "id": null,
    "metadata": {},
    "name": "addContactDialog_AddContactMutation",
    "operationKind": "mutation",
    "text": "mutation addContactDialog_AddContactMutation(\n  $input: ContactCreateInput!\n) {\n  contactCreate(input: $input) {\n    contact {\n      id\n      name\n      email\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5e17fbbd0f719b7cccd6f98ede01aa32";

export default node;
