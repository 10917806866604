import { useEffect } from "react"
import { useFormContext } from "react-hook-form"

import {
  Button,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Heading,
  Input,
} from "@utopia/ui"

import type { BankAccount, ExistingBankAccount } from "../index.js"
import type { AddContactData } from "./add-contact-schema.js"
import {
  BankAccountFromProps,
  ExistingBankAccountDisplay,
} from "./bank-account-display.js"

interface ContactFormProps {
  existingBankAccounts?: ExistingBankAccount[]
  stagedBankAccounts?: BankAccount[] | null
  stagedContact: AddContactData | null
  setStagedContact: (contact: AddContactData | null) => void
  setStagedBankAccounts: (bankAccounts: BankAccount[] | null) => void
  setCurrentBankAccount: (bankAccount: BankAccount | null) => void
  handleAddBankAccount: (open: boolean) => void
  handleEditBankAccount: (open: boolean) => void
}

export const ContactForm: React.FC<ContactFormProps> = ({
  stagedBankAccounts,
  handleAddBankAccount,
  handleEditBankAccount,
  existingBankAccounts,
  setStagedBankAccounts,
  setCurrentBankAccount,
  stagedContact,
  setStagedContact,
}) => {
  const { control, watch } = useFormContext<AddContactData>()

  const watchName = watch("name")
  const watchEmail = watch("email")

  useEffect(() => {
    if (watchName && stagedContact) {
      setStagedContact({ ...stagedContact, name: watchName })
    }
    if (watchEmail && stagedContact) {
      setStagedContact({ ...stagedContact, email: watchEmail })
    }
  }, [watchName, watchEmail, stagedContact, setStagedContact])

  return (
    <div className="scrollbar grid max-h-[500px] space-y-4 overflow-auto py-4">
      <Heading className="font-medium">Personal details</Heading>
      <FormField
        control={control}
        name="name"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-normal">Contact name</FormLabel>
            <FormControl>
              <Input
                className="rounded"
                {...field}
                placeholder="Enter contact name"
                defaultValue={watchName}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="email"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-normal">Email address</FormLabel>
            <FormControl>
              <Input
                className="rounded"
                {...field}
                placeholder="Enter email address"
                defaultValue={watchEmail}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <Heading className="font-medium">Bank accounts</Heading>
      <FormField
        control={control}
        name="bankAccounts"
        render={() => (
          <FormItem>
            <div className="flex w-full flex-col gap-2">
              {existingBankAccounts &&
                existingBankAccounts.map((bankAccount) => (
                  <div
                    key={bankAccount.id}
                    className="flex rounded border border-gray-200 p-2"
                  >
                    <ExistingBankAccountDisplay bankAccount={bankAccount} />
                  </div>
                ))}
              {stagedBankAccounts &&
                stagedBankAccounts.map((bankAccount) => (
                  <div
                    key={bankAccount.idempotencyKey}
                    className="flex rounded border border-gray-200 p-2"
                  >
                    <BankAccountFromProps
                      bankAccount={bankAccount}
                      handleEdit={() => {
                        setCurrentBankAccount(bankAccount)
                        handleEditBankAccount(true)
                      }}
                    />
                  </div>
                ))}
              <Button
                variant="ghost"
                className="flex w-full justify-start border border-dashed border-stone-200"
                type="button"
                onClick={() => handleAddBankAccount(true)}
              >
                + Add bank account
              </Button>
            </div>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}
