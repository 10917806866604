import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { Table, TableBody, TableHead, TableHeader, TableRow } from "@utopia/ui"

import type { contactsTable_ContactsFragment$key } from "./__generated__/contactsTable_ContactsFragment.graphql"
import { ContactTableRow } from "./contact-row.js"

const ContactsFragment = graphql`
  fragment contactsTable_ContactsFragment on Contact @relay(plural: true) {
    id
    name
    email
    bankAccounts {
      bankName
    }

    ...contactRow_ContactsFragment
  }
`

interface ContactsTableProps {
  workspaceSlug: string
  contacts: contactsTable_ContactsFragment$key
  refetchContacts: () => void
  searchQuery: string
}

export function ContactsTable({
  workspaceSlug,
  contacts,
  refetchContacts,
  searchQuery,
}: ContactsTableProps) {
  const contactsData = useFragment(ContactsFragment, contacts).filter((contact) => {
    if (!searchQuery) return true

    if (contact.name.toLowerCase().includes(searchQuery.toLowerCase())) return true
    if (contact.email?.toLowerCase().includes(searchQuery.toLowerCase())) return true
    if (
      contact.bankAccounts.some((account) =>
        account.bankName.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    ) {
      return true
    }

    return false
  })

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Name</TableHead>
          <TableHead>Email</TableHead>
          <TableHead>Bank accounts</TableHead>
          <TableHead />
        </TableRow>
      </TableHeader>
      <TableBody>
        {contactsData.map((contact) => (
          <ContactTableRow
            key={contact.id}
            workspaceSlug={workspaceSlug}
            contact={contact}
            refetchContacts={refetchContacts}
          />
        ))}
      </TableBody>
    </Table>
  )
}
