import mixpanel from "mixpanel-browser"

import { Config } from "#config"

mixpanel.init(Config.VITE_MIXPANEL_TOKEN)

const actions = {
  track: (eventName: string, properties?: Record<string, unknown>) => {
    mixpanel.track(eventName, properties)
  },
  identify: (userId: string) => {
    mixpanel.identify(userId)
  },
  reset: () => {
    mixpanel.reset()
  },
}

export enum NexusEvent {
  onrampTransfer_failed = "nexus:onrampTransfer_failed",
  bankAccount_added = "nexus:bankAccount_added",
  bankAccount_failed = "nexus:bankAccount_failed",
  bankTransfers_discarded = "nexus:bankTransfers_discarded",
  bankTransfers_failed = "nexus:bankTransfers_failed",
  bankTransfers_sent = "nexus:bankTransfers_sent",
  bankTransfers_started = "nexus:bankTransfers_started",
  bankTransfers_edit_failed = "nexus:bankTransfers_edit_failed",
  bankTransfers_edit_success = "nexus:bankTransfers_edit_success",
  stablecoinTransfers_discarded = "nexus:stablecoinTransfers_discarded",
  stablecoinTransfers_failed = "nexus:stablecoinTransfers_failed",
  stablecoinTransfers_sent = "nexus:stablecoinTransfers_sent",
  stablecoinTransfers_started = "nexus:stablecoinTransfers_started",
  onboarding_accountCreated = "nexus:onboarding_accountCreated",
  verification_launchedPersona = "nexus:verification_launchedPersona",
  verification_termsOfServiceAccepted = "nexus:verification_termsOfServiceAccepted",
  deposit_started = "nexus:deposit_started",
  deposit_cancelled = "nexus:deposit_cancelled",
  deposit_naira = "nexus:deposit_naira",
  deposit_crypto = "nexus:deposit_transferCrypto",
  deposit_onlineProvider = "nexus:deposit_onlineProvider",
  deposit_usd = "nexus:deposit_usd",
  deposit_complete = "nexus:deposit_complete",
}

export const Mixpanel = actions
