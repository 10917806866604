import { useNavigate, useParams } from "@tanstack/react-router"

import { Button, Heading, Text } from "@utopia/ui"

import { Mixpanel, NexusEvent } from "#utils/mixpanel.js"

export const EmptyBankTransfers = () => {
  const navigate = useNavigate()
  const { slug } = useParams({
    from: "/authenticated-route/$slug",
  })
  const onClick = async () => {
    Mixpanel.track(NexusEvent.bankTransfers_started)
    await navigate({ to: "/$slug/bank-transfers/new", params: { slug } })
  }

  return (
    <div className="flex h-full items-center justify-center">
      <div className="u-sm:max-w-[400px] u-md:max-w-[480px] flex flex-col items-center justify-center gap-6">
        <img
          src="/empty-states/usd-transfers.png"
          className="h-[194px] w-[200px]"
          alt="bank transfers"
        />

        <div>
          <Heading size="md" className="text-center text-[24px]">
            You haven’t sent any USD transfers.
          </Heading>
          <Text size="md" className="mt-2 text-center">
            Transfers you successfully send will show up here.
          </Text>
        </div>
        <Button onClick={onClick} className="w-full">
          Send USD transfer
        </Button>
      </div>
    </div>
  )
}
