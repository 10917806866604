import EditOutlinedIcon from "@mui/icons-material/EditOutlined"

import { Avatar, AvatarFallback, Text } from "@utopia/ui"

import { getInitials } from "#utils/get-initials.js"

import type { BankAccount } from "../index.js"

interface ExistingBankAccount {
  id: string
  bankName: string
  lastFourDigits: string
  accountOwnerName: string
}

export function BankAccountFromProps({
  bankAccount,
  handleEdit,
}: {
  bankAccount: BankAccount
  handleEdit: () => void
}) {
  return (
    <div className=" flex w-full justify-between">
      <div className="space-between flex w-full gap-2">
        <Avatar>
          <AvatarFallback>{getInitials(bankAccount.accountOwnerName)}</AvatarFallback>
        </Avatar>
        <div className="grid">
          <Text>{bankAccount.accountOwnerName}</Text>
          <Text size="xs">{bankAccount.bankName}</Text>
        </div>
      </div>
      <div>
        <EditOutlinedIcon
          type="button"
          className="size-md mt-2 cursor-pointer"
          onClick={() => {
            handleEdit()
          }}
        />
      </div>
    </div>
  )
}

export function ExistingBankAccountDisplay({
  bankAccount,
}: {
  bankAccount: ExistingBankAccount
}) {
  return (
    <div className=" flex w-full justify-between">
      <div className="space-between flex w-full gap-2">
        <Avatar>
          <AvatarFallback>{getInitials(bankAccount.accountOwnerName)}</AvatarFallback>
        </Avatar>
        <div className="grid">
          <Text>{bankAccount.accountOwnerName}</Text>
          <Text size="xs">{bankAccount.bankName}</Text>
        </div>
      </div>
    </div>
  )
}
