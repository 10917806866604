import { Route, lazyRouteComponent } from "@tanstack/react-router"

import { authenticatedRoute } from "#router"

export const workspaceRoute = new Route({
  getParentRoute: () => authenticatedRoute,
  path: "/$slug",
  component: lazyRouteComponent(() => import("./index.js"), "WorkspaceLayout"),
  loader: (...args) => {
    return import("./index.js").then((d) => d.loader(...args))
  },
})
